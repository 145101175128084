import React, { Component } from "react"
import $ from 'jquery';
import { connect } from "react-redux"
import Helmet from "react-helmet"
import { Redirect } from "react-router-dom";
import { login, mobileLogin, salesForceLogin } from "../actions/auth"

const style = {
  backgroundImage: "url(" + "images/background.png" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minHeight: 500
}


const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      mobile: '',
      loading: false,
      selectedTab: 1,
      isValid: true,
      errorMsg: '',
      passwordType: 'password',
      passwordHide: true,
      isValidEmailId: false,
      loginCounter: 0,
      isError:false
    };;
    this.handleChange = this.handleChange.bind(this)
    this.handleMobile = this.handleMobile.bind(this)
    this.handleMobileSubmit = this.handleMobileSubmit.bind(this)
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
  }

  componentDidMount() {
    $('.labelFocus input').change(function () {
      var $this = $(this);
      if ($this.val())
        $this.addClass('filled')
      else
        $this.removeClass('filled')
    })
  }

  // handleTab = (value) => {
  //   let mystate = initial;
  //   mystate.selectedTab = value;
  //   this.setState(mystate);
  // }

  switchTab=(val)=>{
    this.props.handleTab(val)
  }

  handleChange = (event) => {
    if (event.target.name == "email") {
      if (!isValidEmail(event.target.value)) {
        this.setState({
          isError: true,
          errorMsg:"Enter valid email address"
        })
      }
      else {
        this.setState({
          isError: false,
          errorMsg:"",
          isValidEmail:true
        })
      }
    if (event.target.value=="") {
        this.setState({
          isError: false,
          errorMsg:""
        })

      }
    }
    if (event.target.name == "password") {
      if(event.target.value){
        this.setState({isError:false,errorMsg:''})
      }
    }
    this.setState({ [event.target.name]: event.target.value });

  }

  handleMobile = (e) => {
    const reg = /^[0]?[6789]\d{9}$/;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (e.target.value !== '') {
      if (!pattern.test(e.target.value)) {
        this.setState({ isValid: false, errorMsg: "Please enter only number.", mobile: "" });
        document.getElementById('mobile').value = "";
      } else if (e.target.value.length === 10) {
        if (reg.test(e.target.value)) {
          this.setState({ isValid: true, errorMsg: "", mobile: e.target.value });
        } else {
          this.setState({ isValid: false, errorMsg: "Please enter valid mobile number.", mobile: e.target.value });
        }
      } else {
        this.setState({ isValid: true, errorMsg: "", mobile: e.target.value });
      }
    } else {
      this.setState({ isValid: false, errorMsg: "", mobile: e.target.value });
    }
  }

  handleMobileSubmit(e) {
    e.preventDefault()
    const { history, dispatch } = this.props
    let data = {
      mobile_no: this.state.mobile
    }

    dispatch(mobileLogin(data)).then((response) => {
      if (response.responseCode != undefined && response.responseCode == "200") {
        this.setState({ isValid: true, errorMsg: '' });
        history.push("/verify_otp");
      } else {
        this.setState({ isValid: false, errorMsg: response });
      }
    }).catch((error) => {
      this.setState({ isValid: false, errorMsg: error });
    });
  }

  handleLoginSubmit(e) {
    e.preventDefault()
    const { history, dispatch } = this.props
    let data = {
      email: this.state.email,
      password: this.state.password
    }
    if(!isValidEmail(this.state.email)){
      this.setState({
        isError:true,
        errorMsg:"Email is a required."
      })
      return;
    }
    if(!(this.state.password)){
      this.setState({
        isError:true,
        errorMsg:"Password is a required."
      })
      return;
    }
    dispatch(login(data)).then((response) => {
      if (response.status === 'success') {
        this.setState({ isError: false, errorMsg: '' })
        dispatch({type:'LOGIN_WITH_PASSWORD_SUCCESS',payload:{email:this.state.email}})
        history.push("/verify_otp");
      } else {
        this.setState({ isError: true, errorMsg: response?.message });
      }
    }).catch((error) => {
      this.setState({ isError: true, errorMsg: error });
    });
  }

  handlePassword = () => {
    if (this.state.passwordHide === true) {
      this.setState({ passwordHide: false, passwordType: 'text' });
    } else {
      this.setState({ passwordHide: true, passwordType: 'password' });
    }
  }
  handleSubmitClick = ()=>{
  }
  render() {
    const { isLoading, user_id , selectedTab } = this.props;
    const {  mobile, isValid, email, password } = this.state

    return (
      <>
        <Helmet>
          <title> Lender - Login </title>
        </Helmet>
        {false ? (
          <div className="loading">Loading&#8230;</div>
        ) : ''}
        <div className="flex-w flex-tr">

          <div className="size-50 bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md primary-card">
            <div className="textpart">
              <h4 className="mtext-105 cl2 txt-left pb-1 pb-lg-5"><img src="images/logo-eduvan-title.png" /></h4>
              <h1 className="titfnt text-white">
                <span className="d-block">Welcome to Lender Portal</span>
              </h1>
            </div>
          </div>

          <div className="size-210 bor10 flex-w flex-col-m p-lr-93 p-tb-30 p-lr-15-lg w-full-md login-form-img">
            <div className="lendar_centered" >

              {/* <ul className="nav nav-pills nav-fill c_nav_2 justify-content-center">
                <li className={`nav-link ico_mobile ${selectedTab === 2 ? "selected" : ""}`} style={{ cursor: 'pointer' }} onClick={() => this.switchTab(2)}>
                  <a href={void (0)} >Mobile</a>
                </li>
                <li className={` nav-link ico_email ${selectedTab === 1 ? "selected" : ""}`} style={{ cursor: 'pointer' }} onClick={() => this.switchTab(1)}>
                  <a href={void (0)}>Email</a>
                </li>
              </ul> */}

              
                <form onSubmit={this.handleLoginSubmit}>
                  <div className="fields_bg_1">
                    <div className="lendar_minh1">
                      <h3>Sign in</h3>
                      {this.state.isError ? (<div class="alert alert-danger" role="alert">
                        {this.state.errorMsg}
                      </div>):("")}
                      <div className="bor8 m-b-20 how-pos4-parent input-group labelFocus">
                        <span>Email ID</span>
                        <input
                          type="email"
                          className="stext-111 cl2 plh3 size-116  p-r-15"
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="bor8 m-b-20 how-pos4-parent input-group labelFocus">
                        <span>Password</span>
                        <input
                          type={this.state.passwordType}
                          className="stext-111 cl2 plh3 size-116  p-r-15"
                          id="password"
                          name="password"
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        {
                          this.state.password &&
                          <div className="input-group-addon input-group-addon-sty eye-position" onClick={() => this.handlePassword()}>
                            {
                              this.state.passwordHide ? (
                                <i style={{ fontSize: '20px' }} className="fa fa-eye" aria-hidden="true" />
                              ) : (<i style={{ fontSize: '20px' }} className="fa fa-eye-slash" aria-hidden="true" />)
                            }
                          </div>
                        }
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                      <div className="checkbox">
                        <label className="remember-text">
                          <input type="checkbox" name="remember" className="checkbox-input" /> Remember me
                        </label>
                      </div>
                      <a className="link" href="/edForgotpassword">
                        <span>Forgot Password?</span>
                      </a>
                    </div>
                    <button
                      type="submit"
                      disabled={isLoading? true : false}
                      // style={email !=='' && password !==''?{}:btn}
                      className="btn-full btn login-btn"
                    >
                      {isLoading?<div class="spinner-border text-light" role="status">
                      <span class="sr-only">Loading...</span>
                      </div>:'Login'}
                    </button>
                  </div>
                </form>
              
                {/* <form onSubmit={this.handleMobileSubmit}>
                  <div className="fields_bg_1">
                    <div className="lendar_minh1">
                      <h3>Sign in</h3>
                      <div className="">
                        <label>
                          You will receive an OTP on this number
                        </label>
                      </div>

                      <div className="bor8 m-b-20 how-pos4-parent input-group labelFocus d-flex flex-column">
                        <span>Mobile Number</span>
                        <div className="d-flex justify-content-between align-items-center">
                          <input
                            type="text"
                            className="stext-111 cl2 plh3 size-116  p-r-15"
                            id="mobile"
                            // placeholder="Mobile Number"
                            name="mobile"
                            value={mobile ? mobile : ''}
                            maxLength={10}
                            onChange={this.handleMobile}
                            autoComplete="off"
                          />
                          {mobile && mobile.length === 10 && isValid && <i class="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>}
                        </div>


                      </div>
                      {isValid == false && this.state.errorMsg != '' ? (
                        <div className="form-group">
                          <div className={"alert alert-danger"} role="alert">
                            {this.state.errorMsg}
                          </div>
                        </div>
                      ) : ''
                      }
                    </div>

                    <button
                      disabled={mobile.length === 10 && isValid === true ? false : true}
                      type="submit"
                      className={`btn-full btn`}
                    // style={mobile.length ===10 && isValid === true?{}:btn}
                    >
                      Login
                    </button>
                  </div>
                </form>
          */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, isLoading, user_id, token_id } = state.auth;
  const { message } = state.message;
  const { Mobile_NO } = state.user;
  return {
    isLoggedIn,
    message,
    isLoading,
    user_id,
    token_id,
    Mobile_NO,
  };
}

export default connect(mapStateToProps)(Login);
