export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const auth_token = localStorage.getItem('auth_token'); 
  if (auth_token) {
    // For Spring Boot back-end
    // return { Authorization: "Bearer " + user.accessToken };

    // for Node.js Express back-end
    return { "x-auth-token": auth_token,'x-api-key':process.env.REACT_APP_AUTH_API_KEY };
  } else {
    return {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','x-api-key':process.env.REACT_APP_AUTH_API_KEY};
  }
}
