import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Sidebar from "../common/sidebar";
import { Redirect } from "react-router-dom";
import {
  getLeadProfile,
  getDocByType,
  getBankDocuemnt,
  getGroupList,
  getAddress,
  getLeadOtherDocuemnt,
  getAddressProofDoc,
  getAddressProof,
  getLeadPanDocuemnt,
  getLeadProfileDocuemnt,
  getLenderLeadDetails,
  openLeadProfileModel,
  geBbureau,
  geBbureauHardpull,
  getRepayment,
  getDocumentCount,
  groupEmailSearch,
  clearEmailSearch,
  sendEmailCAM,
  getallLeadDoc,
  getApplicationAddr,
  getRepaymentSchedule,
} from "../actions/users";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import dateFormat from "dateformat";
import RaiseQueryPopup from "../common/RaiseQueryPopup";
import { Scrollbar } from "react-scrollbars-custom";
import { openRaiseQuery, openeDownloadReport } from "../actions/model";
import HtmlIframe from "./htmlIframe";
import Header from "../common/header";
import { Link } from "react-router-dom";
import moment from 'moment';

const percentage = 30;
const APP = 1;
const DOC = 2;
const PRO = "PRO";
const NODOC = "NODOC";
const FAIL = "FAIL";
class LeadDetails extends Component {
  // pdfGenerate = () => {
  //   var doc = new jsPDF('landscape', 'px', 'a4', 'false');
  //   doc.addImage(logo,'PNG',65,20,500,400);
  //   doc.save('A.pdf')
  // };

  constructor(props) {
    super(props);
    this.state = {
      bankSrc: "",
      maxRequestLength: "5242880",
      company_name: "",
      monthly_income: "",
      gmapsLoaded: false,
      isValidPan: true,
      errorMsg: "",
      onBoarding: 0,
      rent_amount: "",
      house: 0,
      isBackUploading: false,
      isSuccess: "",
      successMsg: "",
      isProfileSuccess: "",
      profileSuccessMsg: "",
      driving_dl: "",
      pan: null, // "ABCTY1234D",
      firstname: "",
      lastname: "",
      userstatus: "PENDING",
      sfid: "",
      product: "",
      email: "",
      mobile: "",
      aadhar_number: "",
      card: "",
      loan_amount: "",
      profileId: null,
      panId: null,
      frontId: null,
      backId: null,
      limit: 0,
      dob: "", //new Date
      gender: "",
      pincode: "",
      address_pin: "",
      address: "",
      state: "",
      city: "",
      addressList: [],
      addressProof: [],
      camReport: "",
      selectedAddress: "",
      current_address: 0,
      selectedLeadAddress: "",
      profession: "",
      selectedTab: 1,
      defaultTab: 0,
      profileType: "",
      profileBase: "",
      statementType: "",
      statementBase: "",
      panType: "",
      panBase: "",
      frontProofType: "",
      frontAadharProofBase: "",
      backAadharProofBase: "",
      frontDrivingProofBase: "",
      backDrivingProofBase: "",
      frontVoterProofBase: "",
      backVoterProofBase: "",
      frontPassportProofBase: "",
      backPassportProofBase: "",
      backProofType: "",
      bankDocument: [],
      selectedPlan: "",
      frontFileType: 0,
      backFileType: 0,
      panFileType: 0,
      ifsc: "",
      acc_name: "",
      acc_no: "",
      bank: "",
      resident_type: "",
      showDocument: "",
      modalTitle: "",
      net_tenure: "",
      loan_amount: "",
      upfront_amount: "",
      emi_amount: "",
      loan_startdate: "",
      interest_rate: "",
      moratorium_type: "",
      moratorium_amount: "",
      moratorium_duration: "",
      nextDet: null,
      prevDet: null,
      current_status: "all",
      bank_data: [],
      datarrr: [],
      bureaurs: [],
      harddata: {},
      Ifdata: true,
      civil_score: "",
      martialstatus: "",
      alldoccount: {},
      occupation: "",
      sector: "",
      Bankstatement: "",
      slip: "",
      foiremi: "",
      title_satge: "",
      otherDocData: [],
      userId: null,
      imageObj: null,
      imageTypeis: null,
      viewIs: APP,
      apiCall: PRO,
      email_search: [],
      selected: [],
      selectedItem: [],
      selectGroup: [],
      bureaurData: [],
      html_code: "",
      bank_statement_list: [],
      salary_slip_list: [],
      show_docType: "",
      currentAddress: "",
      app_id: "-",
      customerEligibilityData:"",
      BankDeatils:"",
      repaymentScheduleList:[],

    };
    this.getDocDetails = this.getDocDetails.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search); // id=123
    let id = params.get("id"); // 1
    this.getLeadDetails(id);
    }

  getDocTypeString = (type) => {
    let ty = type.toLowerCase();
     return `data:application/${ty};base64,`
}

  getLeadDocuments = (id) => {
    //  this.props.dispatch(getallLeadDoc(id)).then(response => {
     this.props.dispatch(getallLeadDoc(id)).then(response => {
        if(response.status === "success"){
    const bankData = response?.data[0]?.files?.length > 0 ? response.data[0].files.filter(ele => ele.Document_Type__c === "Bank Statement"):[];
    const address = response?.data[0]?.files?.length > 0  ? response.data[0].files.filter(ele => ele.Document_Type__c === "BACK" && ele.Document_Category__c==="ID Proof"):[];
    const singedAgree = response?.data[0]?.signAgreementData?.length > 0 ? response?.data[0]?.signAgreementData.map(ele =>{ return {fileContent:ele.base64Data,FileExtension:"pdf",Title:"Singed Agreement",FileType:"PDF",Document_Type__c:"Signed Agreement"}}):[]
            // this.setState({kycDoc:response.data[0].files,bureaurData:response.data[0].bureauList,bank_data:bankData,addressProof:address,otherDocData:singedAgree})
            this.setState(prev => {return{...prev,kycDoc:response.data[0].files,bureaurData:response.data[0].bureauList,bank_data:bankData,addressProof:address,otherDocData:singedAgree}})
        }else{

        }
     })
  }

  getDocDetails = (type, finType) => {
    this.setState({
      imageObj: null,
    });
    let dataObj = {
      sfid: this.state.userId,
      doc_type: type,
    };
    this.props
      .dispatch(getDocByType(dataObj))
      .then((response) => {
        if (response.status == "success") {
          let imageDetails = response.imageData;
          let imgArr = [];
          let imgType = "";
          for (let i = 0; i < imageDetails.length; i++) {
            const singImg = imageDetails[i];
            if (type == "Financial") {
              if (finType == singImg.document_type) {
                imgArr.push(
                  "data:application/pdf;base64," + singImg.base64.base64
                );
                imgType = singImg.filetype;
              }
            } else {
              imgType = singImg.filetype;
              imgArr.push("data:image/jpg;base64," + singImg.base64.base64);
            }
          }
          if (imgArr.length == 0) {
            this.setState({ apiCall: NODOC });
          }
          this.setState({
            imageObj: imgArr,
            imageTypeis: imgType,
          });
        } else {
          this.setState({ apiCall: FAIL });
        }
      })
      .catch((error) => {
        this.setState({ apiCall: FAIL });
      });
  };

  getLeadDetails = (lead_id) => {

    this.props.dispatch(getLenderLeadDetails(lead_id)).then((response) => {
      if (response.status === "success") {
        let getData = response.data[0];
        let planData = response.data[0];
        const limitId = getData.Account.Application__c || ""
        const permenentAdd = `${
          getData?.Account?.CKYC_Flat_No__c
            ? getData.Account.CKYC_Flat_No__c + ", "
            : ""
        }${
          getData?.Account?.CKYC_Building__c
            ? getData.Account.CKYC_Building__c + ", "
            : ""
        }${
          getData?.Account?.CKYC_Street__c
            ? getData.Account.CKYC_Street__c + ", "
            : ""
        }${
          getData?.Account?.CKYC_City__c
            ? getData.Account.CKYC_City__c + ", "
            : ""
        }${
          getData?.Account?.CKYC_State__c
            ? getData.Account.CKYC_State__c + ", "
            : ""
        }${
          getData?.Account?.CKYC_Pincode__c
            ? getData.Account.CKYC_Pincode__c
            : ""
        } `;
        const currentAdd = `${
          getData?.Account?.Current_Flat_No__c
            ? getData.Account.Current_Flat_No__c + ", "
            : ""
        }${
          getData?.Account?.Current_Building__c
            ? getData.Account.Current_Building__c + ", "
            : ""
        }${
          getData?.Account?.Current_Street__c
            ? getData.Account.Current_Street__c + ", "
            : ""
        }${
          getData?.Account?.Current_City__c
            ? getData.Account.Current_City__c + ", "
            : ""
        }${
          getData?.Account?.Current_State__c
            ? getData.Account.Current_State__c + ", "
            : ""
        }${
          getData?.Account?.Current_Pincode__c
            ? getData.Account.Current_Pincode__c
            : ""
        } `;

        this.setState({
          lead_id,
          firstname:
            getData && getData.Applicant_s_First_Name__c
              ? getData.Applicant_s_First_Name__c
              : "-",
          lastname:
            getData && getData.Applicant_s_Last_Name__c
              ? getData.Applicant_s_Last_Name__c
              : "-",
          aadhar_number:
            getData && getData.Adhaar__c ? getData.Adhaar__c : null,
          opp_status: getData && getData.StageName ? getData.StageName : "-",
          userstatus:
            getData && getData.StageName ? getData.StageName : "PENDING",
          sfid: getData && getData.Id ? getData.Id : "-",
          app_id: getData.Id ? getData.Id : "-",
          email: getData && getData.Email__c ? getData.Email__c : "",
          mobile:
            getData && getData.Mobile_Number__c ? getData.Mobile_Number__c : "",
          pan: getData && getData.PAN__c ? getData.PAN__c : null,
          driving_dl:
            getData && getData.driving_license__c
              ? getData.driving_license__c
              : null,
          foiremi:
            getData?.foirData && getData?.foirData[0]?.FOIR__c
              ? getData.foirData[0].FOIR__c
              : 0,
          title_satge: getData && getData.StageName ? getData.StageName : "N/A",
          currentAddress: currentAdd ? currentAdd.trim() : "",
          permenentAddress: permenentAdd ? permenentAdd.trim() :"",

          card: "XXXXXXX76A",
          dob:
            getData.Account && getData.Account.Date_Of_Birth_Applicant__c
              ? getData.Account.Date_Of_Birth_Applicant__c
              : "",
          pincode:
            getData.Account && getData.Account.Current_Pincode__c
              ? getData.Account.Current_Pincode__c
              : "",
          gender:
            getData.Account && getData.Account.Gender__c
              ? getData.Account.Gender__c
              : "-",
          martialstatus:
            getData.Account && getData.Account.Marital_Status__c
              ? getData.Account.Marital_Status__c
              : "-",
          selectedAddress: getData && getData ? getData.current_address : "",
          rent_amount:
            getData.Account && getData.Account.Rent_Amount__c
              ? getData.Account.Rent_Amount__c
              : "-",
          house: getData.Account && getData.Account.Rent_Amount__c ? 2 : 1,
          profession:
            getData && getData.Occupation__c ? getData.Occupation__c : "-",
          resident_type:
            getData.Account && getData.Account.Resident_Type__c
              ? getData.Account.Resident_Type__c
              : "-",

          monthly_income:
            getData && getData.Monthly_Income__c
              ? getData.Monthly_Income__c
              : null,
          company_name:
            getData && getData.Employer_Name__c
              ? getData.Employer_Name__c
              : "-",
          occupation:
            getData && getData.Occupation__c ? getData.Occupation__c : "-",
          Bankstatement:
            getData && getData.bank_statement ? getData.bank_statement : null,
          slip: getData && getData.salary_slip ? getData.salary_slip : null,

          sector:
            getData && getData.Account.Employer_Type__c
              ? getData.Account.Employer_Type__c
              : "-",
          civil_score:
            getData && getData.Bureau_Score__c ? getData.Bureau_Score__c : 0,
          product:
            getData.Merchant_Product__r && getData.Merchant_Product__r.Name
              ? getData.Merchant_Product__r.Name
              : "-",
          loan_amount:
            getData && getData.Applied_Loan_Amount__c
              ? getData.Applied_Loan_Amount__c
              : "0",
          net_tenure: getData && getData.Tenure1__c ? getData.Tenure1__c : "",
          upfront_amount:
            getData && getData.Upfront_Payment_Amount__c
              ? getData.Upfront_Payment_Amount__c
              : "0",
          emi_amount: getData?.EMI_Amount__c ? getData?.EMI_Amount__c:"0",
          loan_startdate: getData?.Payment_Plan__r?.First_EMI_date__c
            ? getData.Payment_Plan__r.First_EMI_date__c
            : "",
          advanceEmi: getData?.Payment_Plan__r?.Total_Advance_Emi_Amount__c
            ? getData.Payment_Plan__r.Total_Advance_Emi_Amount__c
            : 0,
          downPayment: getData?.Payment_Plan__r?.Down_Payment__c
            ? getData.Payment_Plan__r.Down_Payment__c
            : 0,
          interest_rate:
            planData && planData.fixed_rate__c ? planData.fixed_rate__c : "",
          moratorium_type:
            planData && planData.moratorium_type__c
              ? planData.moratorium_type__c
              : "",
          moratorium_duration:
            planData && planData.moratorium_duration__c
              ? planData.moratorium_duration__c
              : "",
          moratorium_amount:
            planData && planData.moratorium_amount__c
              ? planData.moratorium_amount__c
              : "0",
          nextDet: getData && getData.nextDet ? getData.nextDet : null,
          prevDet: getData && getData.prevDet ? getData.prevDet : null,
          customerEligibilityData:getData.customerEligibilityData ? getData.customerEligibilityData : "",
          bureauLiabilitiesData:getData.bureauLiabilitiesData ? this.getBureauLiabilitiesData(getData.bureauLiabilitiesData) : [],
          BankDeatils:getData?.BankDeatils[0] ? getData.BankDeatils[0] : "",
        });
        // this.getLeadProfile(getData.sfid);
        this.BureaueDetails(limitId,getData?.bureauLiabilitiesData?.length > 0 ? true:false);
      }
    });
    this.props.dispatch(getApplicationAddr(lead_id)).then(response => {
      if(response.status === "success"){
        this.setState(prev => {return{...prev,addressList:response.data}})
      }
})
    this.props.dispatch(getRepaymentSchedule(lead_id)).then(response => {
      if(response?.status === "success"){
        console.log("coming",response.data);
        this.setState(prev => {return{...prev,repaymentScheduleList:response.data}})
      }
})
    this.getLeadDocuments(lead_id)
  };

BureaueDetails = (id,liabilityData) => {
  // this.props.dispatch(geBbureau(id)).then(response => {
  this.props.dispatch(geBbureau(id)).then(response => {
    if(response.status === "success"){
      if(liabilityData){
     this.setState({bureauAnalysisData:response.data.bureauAnalysisData})
      }else{
        this.setState({bureauAnalysisData:response.data.bureauAnalysisData,bureauLiabilitiesData:this.getBureauLiabilitiesData(response.data.bureauLiabilitiesData)})
      }
    }else{

    }
  })
}

 getBureauLiabilitiesData = (data) => {
  if(!data || data.length === 0){
    return data;
  }
  const dataWithTotal = data;
  let item = {
      Id: "a0ZC3000000qU0QMAU",
      Active_Tradelines__c: "Total",
      Tradelines__c: 0,
      O_s_Balance__c: 0,
      EMI_in_Bureau__c: 0,
      Obligated_EMI__c: 0,
      Imputed_EMI__c: 0,
      isBold:true
  }

  dataWithTotal.forEach(ele => {
    if(ele.Tradelines__c){
      item.Tradelines__c = item.Tradelines__c + ele.Tradelines__c;
    }
    if(ele.O_s_Balance__c){
      item.O_s_Balance__c = item.O_s_Balance__c + ele.O_s_Balance__c;
    }
    if(ele.EMI_in_Bureau__c){
      item.EMI_in_Bureau__c = item.EMI_in_Bureau__c + ele.EMI_in_Bureau__c;
    }
    if(ele.Obligated_EMI__c){
      item.Obligated_EMI__c = item.Obligated_EMI__c + ele.Obligated_EMI__c;
    }
    if(ele.Imputed_EMI__c){
      item.Imputed_EMI__c = item.Imputed_EMI__c + ele.Imputed_EMI__c;
    }
  });
    dataWithTotal.push(item);
  
  return dataWithTotal;
 }

  setDocument = (value, mtitle) => {
    this.setState({ showDocument: value });
    this.setState({ modalTitle: mtitle });
  };

  generatePDF = async () => {
    const pdfData = document.getElementById("content");
    const canvas = await html2canvas(pdfData);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  openLeads = async (id, direction) => {
    // new code

    // const { lead_id } = this.props
    // let all_lead_ids = localStorage.getItem('all_lead_ids')
    // let all_leads_arr = all_lead_ids.split(',')
    // let next_lead = all_leads_arr[all_leads_arr.indexOf(lead_id) + 1];

    // old code
    await this.props.dispatch(openLeadProfileModel(id));
    this.getLeadDetails(id);
  };

  base64toBlob = (data) => {
    let url = "data:application/pdf;base64," + data;
    return url;
  };

  getLeadProfile = (id) => {
  
  };

  kycDocumentsDownload = () => {
    const {kycDoc} = this.state;
    if(kycDoc?.length > 0){
      kycDoc.map(ele =>{
        this.downloadBase64File(ele.FileType.toLowerCase(),ele.fileContent,ele.Title)
      })
  };
}

BureaoDocumentsDownload = () => {
  const {bureaurData} = this.state;
  if(bureaurData?.length > 0) {
    // bureaurData.map(ele => {
    //   this.downloadBase64File("pdf",ele.fileContent,"Bureau Reports.pdf")
    // })
    const linkSource = "data:text/html;base64," + bureaurData[0].fileContent;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "BureauReport.html"
    downloadLink.click();
  }
}
AddressDocumentsDownload = () => {
  const {addressProof} = this.state;
  if(addressProof?.length > 0) {
    addressProof.map(ele =>{
      this.downloadBase64File(ele.FileType.toLowerCase(),ele.fileContent,ele.Title)
    })
  }
}

OtherDocumentsDownload = () => {
  const {otherDocData} = this.state;
  if(otherDocData?.length > 0) {
    otherDocData.map(ele =>{
      this.downloadBase64File(ele.FileType.toLowerCase(),ele.fileContent,ele.Title)
    })
  }
}

  handleEmailSearch = (event) => {
    // this.setState({ [event.target.name]: event.target.value });
    // let str = event.target.value;
    // if (str && str.length > 2) {
    //   let data = { search_name: str };
    //   this.props.dispatch(groupEmailSearch(data)).then((res) => {
    //     if (res && res.length > 0) {
    //       this.setState({ email_search: res });
    //     }
    //   });
    // } else {
    //   this.props.dispatch(clearEmailSearch());
    // }
  };
  selectGroup = (item) => {
    let groupSelected = this.state.selectGroup;
    let groupIdExist = groupSelected.includes(item.id);
    if (!groupIdExist) {
      groupSelected.push(item.id);
      this.setState({ selectGroup: groupSelected });
    } else {
      let index =
        this.state.selectGroup &&
        this.state.selectGroup.findIndex((ele) => ele == item.id);
      this.state.selectGroup && this.state.selectGroup.splice(index, 1);
      this.setState({ selectGroup: this.state.selectGroup });
    }
  };
  onSelectClick = async (row) => {
    if (this.state.selected < 0) {
      let selectEmail = [];
      selectEmail.push(row);
      this.setState({ selected: selectEmail }, () => {
        this.setState({
          email_search: "",
          email_cam: "",
        });
      });
    } else {
      let findEmail =
        this.state.selected &&
        this.state.selected.find((ele) => ele.email == row.email);
      if (!findEmail) {
        this.state.selected && this.state.selected.push(row);
        this.setState({ selected: this.state.selected }, () => {
          this.setState({
            email_search: "",
            email_cam: "",
          });
        });
      } else {
        this.setState({
          email_search: "",
          // email_cam : ""
        });
      }
    }
  };
  renderSearch = (getData) => {
    return getData && getData.length ? (
      <ul className="suggestions">
        {getData.map((item, index) => {
          let className;
          const getData = this.state.selectedItem;
          const isExist = getData.find((ele) => ele.email == item.email);
          // Flag the active suggestion with a class
          if (isExist) {
            className = "suggestion-active";
          }
          return (
            <li
              className={className}
              key={`search-item${index}`}
              onClick={() => this.onSelectClick(item)}
            >
              {item.email}
            </li>
          );
        })}
      </ul>
    ) : (
      ""
    );
    {
      /* <div className="no-suggestions">
      <em>Search not found</em>
    </div> */
    }
  };
  removeEmail = (item) => {
    let index =
      this.state.selected &&
      this.state.selected.findIndex((ele) => ele.email == item);
    this.state.selected && this.state.selected.splice(index, 1);
    this.setState({ selected: this.state.selected });
  };
  financialDocumentsDownload = () => {
    const {bank_data} = this.state;
    if(bank_data?.length > 0){
      bank_data.map(ele =>{
        this.downloadBase64File(ele.FileType.toLowerCase(),ele.fileContent,ele.Title)
      })
    }
  };

  downloadKyc(imgSrc) {
    const linkSource = `${imgSrc}`;
    const downloadLink = document.createElement("a");
    const fileName = "image.jpeg";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  handleDownloadAll = () => {
    this.kycDocumentsDownload();
    // this.financialDocumentsDownload();
    this.BureaoDocumentsDownload();
    this.OtherDocumentsDownload();
    // this.AddressDocumentsDownload();
  };

  openRaiseQuery = () => {
    this.props.dispatch(openRaiseQuery());
  };
  sendEmailCAM = async () => {
    let emailRecId = [];
    this.state.selected &&
      this.state.selected.map((ele) => {
        emailRecId.push(ele.recipient_id);
      });
    let pdfData = document.getElementById("content");
    let canvas = await html2canvas(pdfData);
    let data = canvas.toDataURL("image/png");
    let base64 = data.split(",");
    let emailObj = {
      email_recipient: emailRecId,
      group_id: this.state.selectGroup,
      file: base64 && base64.length > 0 ? base64[1] : data,
    };
    this.props.dispatch(sendEmailCAM(emailObj)).then((res) => {
      if (res.status == "success") {
        this.setState({
          selected: [],
          selectGroup: [],
          email_search: [],
          email_cam: "",
          errorMsg: "",
        });
        window.$("#closeModal_example").trigger("click");
      } else {
        this.setState({ errorMsg: res.message });
      }
    });
  };

 downloadBase64File = (contentType, base64Data, fileName) => {
  let name = fileName;
  if(!fileName.includes(".")){
    name = fileName+"."+contentType;
  }
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}

  render() {
    const {
      isLoggedIn,
      message,
      lead_id,
      lead_profile,
      salesForceToken,
      user_id,
      isLoading,
      opp_sfid,
      repayment,
    } = this.props;
    const {
      bank_data,
      datarrr,
      bureaurs,
      harddata,
      alldoccount,
      bureaurData,
      selected,
      show_docType,
      selectedTab,
      addressList,
      currentAddress,
      permenentAddress,
      bureauAnalysisData,
      bureauLiabilitiesData,
      customerEligibilityData,
      repaymentScheduleList
    } = this.state;

    if (!user_id) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Helmet>
          <title>Lead - Details </title>
        </Helmet>
        {isLoading ? <div className="loading">Loading&#8230;</div> : ""}
        <div id="wrapper">
          <Sidebar />
          <RaiseQueryPopup
            dispatch={this.props.dispatch}
            open_raise_query={this.props.open_raise_query}
          />
          <div id="content-wrapper" className="d-flex flex-column">
            <div className="container-fluid lead_details_header">
              <div className="row align-items-center">
                <div className="d-flex w-100 justify-content-between">
                  <div style={{ flex: "1" }}>
                    <div className="backicon float-left">
                      <Link to="/leads">
                        <img src="img/icon_back.svg" />
                      </Link>
                    </div>
                    <div className="d-flex">
                      <div className="pr_img">
                        {this.state.profileBase ? (
                          <img
                            className="img-profile rounded-circle img_icon_width"
                            src={this.state.profileBase}
                          />
                        ) : (
                          <img
                            className="img-profile rounded-circle"
                            src="img/undraw_profile.svg"
                          />
                        )}
                      </div>
                      <div className="pr_texts">
                        <h5 className="d-block">
                          {this.state.app_id}{" "}
                          <span>
                            <b>{this.state.title_satge}</b>
                          </span>
                        </h5>
                        <p>
                          {this.state.firstname} {this.state.lastname}
                          <span className="mailid ml-3">
                            <img src="img/icon_mail.svg" />
                            {this.state.email}
                          </span>
                          <span className="contactnum">
                            <img src="img/icon_call.svg" /> {this.state.mobile}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3"> */}

                  {/* <form action="" className="search-form nav_search mr-2">
                    <div className="form-group has-feedback">
                      <label htmlFor="search" className="sr-only">Search</label>
                      <input type="text" className="form-control" name="search" id="search" placeholder="Find Cases, Users" />
                    </div>
                  </form> */}

                  <Header
                    title={"lead_details"}
                    isSearchEnable={true}
                    dispatch={this.props.dispatch}
                    type={"global"}
                    history={this.props.history}
                  />

                  {/* </div> */}
                  {/* <div className="col-md-3 text-right"> */}
                  <ul
                    className="topicon_lists"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <a href={void 0}>
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{
                          border: "1px solid #1A1A1A",
                          filter:
                            "drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.1))",
                          borderRadius: "7.5px",
                        }}
                        onClick={this.openRaiseQuery}
                      >
                        <img src="images/ques.svg" />
                        {/* Raise Query */}
                      </button>
                    </a>
                    <a href={void 0}>
                      <button
                        data-toggle="modal"
                        data-target="#exampleModalRight"
                        type="button"
                        class="btn btn-primary"
                        style={{
                          border: "1px solid #1A1A1A",
                          filter:
                            "drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.1))",
                          borderRadius: "7.5px",
                        }}
                      >
                        <img src="images/messages.svg" />
                        {/* Email CAM */}
                      </button>
                    </a>
                    <div className="emailcam">
                      <div
                        class="modal fade drawer right-align"
                        id="exampleModalRight"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Email CAM
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                id="closeModal_example"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div class="modal-body">
                              <div className="recBox">
                                <input
                                  type="text"
                                  className="recipientInput"
                                  name="email_cam"
                                  value={this.state.email_cam}
                                  placeholder="Add Recipient"
                                  onChange={this.handleEmailSearch}
                                />
                                {this.renderSearch(this.state.email_search)}
                                <div className="recInputList">
                                  <ul>
                                    {this.state.selected &&
                                      this.state.selected.length > 0 &&
                                      this.state.selected.map((ele) => {
                                        return (
                                          <>
                                            <li>
                                              {ele.email}{" "}
                                              <img
                                                src="images/icons/icon-close2.png"
                                                alt=""
                                                onClick={(e) =>
                                                  this.removeEmail(ele.email)
                                                }
                                              />
                                            </li>
                                            <br />
                                          </>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>

                              <div class="camGroup">
                                <h5>Email CAM to my groups</h5>

                                {this.state.groupData &&
                                  this.state.groupData.length > 0 &&
                                  this.state.groupData.map((ele) => {
                                    return (
                                      <div
                                        class="panel-group emailCMApopu"
                                        id="accordion"
                                        role="tablist"
                                        aria-multiselectable="true"
                                      >
                                        <div class="panel panel-default">
                                          <div
                                            class="panel-heading"
                                            role="tab"
                                            id="headingOne"
                                          >
                                            <h4
                                              class={"panel-title  imgOpacity"}
                                            >
                                              <img
                                                src={
                                                  this.state.selectGroup &&
                                                  this.state.selectGroup.includes(
                                                    ele.id
                                                  )
                                                    ? "images/icons/check-square.svg"
                                                    : "images/icons/checkbox_noright.svg"
                                                }
                                                alt=""
                                                onClick={(e) =>
                                                  this.selectGroup(ele)
                                                }
                                              />
                                              <a
                                                class="accordion-toggle"
                                                role="button"
                                                data-toggle="collapse"
                                                data-parent="#accordion"
                                                href="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                              >
                                                {ele.title}
                                              </a>
                                            </h4>
                                          </div>
                                          <div
                                            id="collapseOne"
                                            class="panel-collapse collapse in"
                                            role="tabpanel"
                                            aria-labelledby="headingOne"
                                          >
                                            <div class="panel-body">
                                              <ol>
                                                {ele.email &&
                                                  ele.email.length > 0 &&
                                                  ele.email.map((obj) => {
                                                    return (
                                                      <li>
                                                        {obj && obj.email}
                                                      </li>
                                                    );
                                                  })}
                                              </ol>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            <div class="modal-footer">
                              {this.state.errorMsg ? (
                                <p
                                  style={{ color: "red" }}
                                  className="ml-2 pt-1"
                                >
                                  {this.state.errorMsg}
                                </p>
                              ) : (
                                ""
                              )}
                              <button
                                type="button"
                                class="clearBtn1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    selected: [],
                                    selectGroup: [],
                                    email_search: [],
                                    email_cam: "",
                                  });
                                }}
                              >
                                Clear
                              </button>
                              <button
                                type="button"
                                class="emailBtn1"
                                data-dismiss={
                                  this.state.selectGroup &&
                                  this.state.selectGroup.length > 0 &&
                                  this.state.selected &&
                                  this.state.selected.length > 0
                                    ? "modal"
                                    : ""
                                }
                                onClick={(e) => {
                                  (this.state.selectGroup &&
                                    this.state.selectGroup.length > 0) ||
                                  (this.state.selected &&
                                    this.state.selected.length > 0)
                                    ? this.sendEmailCAM()
                                    : this.setState({ errorMsg: true });
                                }}
                              >
                                Email CAM
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a onClick={this.generatePDF}>
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{
                          border: "1px solid #1A1A1A",
                          filter:
                            "drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.1))",
                          borderRadius: "7.5px",
                        }}
                      >
                        <img src="images/download.svg" class="rounded" />
                        {/* Download case */}
                      </button>
                    </a>
                  </ul>
                  {/* </div> */}
                </div>
                <div className="col-md-12">
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <div className="backicon float-left visibility-hidden">
                        <img src="img/icon_back.svg" />
                      </div>
                      <nav className="float-left">
                        <div
                          className="nav nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            className="nav-item nav-link active"
                            id="nav-appdetails-tab"
                            data-toggle="tab"
                            href="#nav-appdetails"
                            role="tab"
                            aria-controls="nav-appdetails"
                            aria-selected="true"
                            onClick={() => this.setState({ viewIs: APP })}
                          >
                            Application Details
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-documents-tab"
                            data-toggle="tab"
                            href="#nav-documents"
                            role="tab"
                            aria-controls="nav-documents"
                            aria-selected="false"
                            onClick={() => this.setState({ viewIs: DOC })}
                          >
                            Documents
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-cam-tab"
                            data-toggle="tab"
                            href="#nav-cam"
                            role="tab"
                            aria-controls="nav-cam"
                            aria-selected="false"
                            onClick={() => this.setState({ viewIs: DOC })}
                          >
                            CAM
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-repayment-tab"
                            data-toggle="tab"
                            href="#nav-repayment"
                            role="tab"
                            aria-controls="nav-repayment"
                            aria-selected="false"
                            onClick={() => this.setState({ viewIs: DOC })}
                          >
                            Repayment
                          </a>
                          {/* {this.state.opp_status == "Closed" ||
                          this.state.opp_status == "Disbursed" ||
                          this.state.opp_status == "Loan Disbursed" ||
                          this.state.title_satge == "Closed" ||
                          this.state.title_satge == "Disbursed" ||
                          this.state.title_satge == "Loan Disbursed" ? (
                            <a
                              className="nav-item nav-link"
                              id="nav-repayments-tab"
                              data-toggle="tab"
                              href="#nav-repayments"
                              role="tab"
                              aria-controls="nav-repayments"
                              aria-selected="false"
                            >
                              Repayments
                            </a>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </nav>
                    </div>
                    {/* <div className="col-md-4 text-right tbuttons">
      <button className="btn btn-dark mr-4"><img src="img/icon_approve.svg" /> Approve</button>
      <button className="btn btn-primary"><img src="img/icon_declare.svg" /> Decline</button>
  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 pt-2">
                    <div className="card">
                      <div className="card-body">
                        <div className="tab-content" id="nav-tabContent">
                          {/* nav appdetails tab */}
                          <div
                            className="tab-pane fade active show"
                            id="nav-appdetails"
                            role="tabpanel"
                            aria-labelledby="nav-appdetails-tab"
                          >
                            <div className="row">
                              <div className="col-md-3">
                                <div className="whiteboxed">
                                  <h6>Financial Borrower Details</h6>
                                  <h4>
                                    <b>
                                      {" "}
                                      {this.state.firstname}
                                      {this.state.lastname}
                                    </b>
                                  </h4>
                                  <div className="personinfo">
                                    {this.state.gender}
                                    <p className="vertical-line"></p>
                                    <span className="dob">
                    
                                      {dateFormat(this.state.dob, "dd-mmm-yy")}
                                    </span>
                                    <span className="vertical-line"></span>
                                    <span className="maritalstatus">
                                      {this.state.martialstatus}
                                    </span>
                                  </div>
                                  <ul className="prooflist">
                                    <li className="card">
                                      PAN Number
                                      {this.state.pan ? (
                                        <span
                                          style={{
                                            color: "#1251F1",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {"XXXXXX"+this.state.pan.slice(6)}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </li>
                                    <li className="aadhaar">
                                      Aadhar Number
                                      {this.state.aadhar_number ? (
                                        <span
                                          style={{
                                            color: "#1251F1",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {this.state.aadhar_number}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </li>
                                    <li className="card">
                                      Driving License
                                      {this.state.driving_dl ? (
                                        <span
                                          style={{
                                            color: "#1251F1",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.getDocDetails("DL")
                                          }
                                          data-toggle="modal"
                                          data-target="#docModel"
                                        >
                                          {this.state.driving_dl}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </li>
                                  </ul>
                                  <h4>
                                    <b>Income Details</b>
                                  </h4>
                                  <div className="personinfo">
                                    {this.state.occupation}
                                    <p className="vertical-line"></p>
                                    <span className="profession">
                                      {this.state.sector}    
                                    </span>
                                    <p className="vertical-line"></p>
                                    <span className="companydetails">
                                      {this.state.company_name}
                                    </span>
                                  </div>
                                  <ul className="prooflist">
                                    <li className="wallet">
                                      Monthly Income
                                      {this.state.monthly_income ? (
                                        <span>
                                          ₹ {this.state.monthly_income}
                                        </span>
                                      ) : (
                                        <span>N/A</span>
                                      )}
                                    </li>
                                    <li className="stmt">
                                      Bank Statement
                                      {
                                        // this.state.Bankstatement ?
                                        //   <span style={{ color: "#1251F1", cursor: "pointer" }} onClick={() => this.getDocDetails('Financial', 'Bank Statement')} data-toggle="modal" data-target="#docModel">{this.state.Bankstatement}</span>
                                        //   : <span>N/A</span>

                                        this.state.bank_statement_list.length >
                                        0 ? (
                                          <span
                                            style={{
                                              color: "#1251F1",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.setState({
                                                show_docType:
                                                  "bank_statement_list",
                                              })
                                            }
                                            data-toggle="modal"
                                            data-target="#doc_type_Model"
                                          >
                                            {
                                              this.state.bank_statement_list
                                                .length
                                            }
                                          </span>
                                        ) : (
                                          <span>N/A</span>
                                        )
                                      }
                                    </li>
                                    {/* <li className="doc">
                                      Salary Slips
                                      {
                                        // this.state.slip ?
                                        //   <span style={{ color: "#1251F1", cursor: "pointer" }} onClick={() => this.getDocDetails('Financial', 'Salary Slip')} data-toggle="modal" data-target="#docModel">{this.state.slip}</span>
                                        //   : <span>N/A</span>

                                        this.state.salary_slip_list.length > 0 ?
                                          <span style={{ color: "#1251F1", cursor: "pointer" }} onClick={() => this.setState({ show_docType: "salary_slip_list" })} data-toggle="modal" data-target="#doc_type_Model">{this.state.salary_slip_list.length}</span>
                                          : <span>N/A</span>

                                      }
                                    </li> */}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="whiteboxed">
                                  <h6>Product Plan Details</h6>
                                  <div className="row">
                                    <div className="col-md-7">
                                      <h4>
                                        {this.state.product}{" "}
                                        <span>
                                          (Tenure - {this.state.net_tenure}{" "}
                                          Months)
                                        </span>
                                      </h4>
                                    </div>
                                    {/* <div className="col-md-5">
                                      <div className="d-flex">
                                        <div className="col-md-6 pl-0 pr-0">
                                          <h5 className="s1">
                                            FOIR post Eduvanz EMI
                                          </h5>
                                        </div>
                                        <div className="">
                                          <div
                                            style={{ width: 80, height: 80 }}
                                          >
                                            <CircularProgressbar
                                              // value={percentage}
                                              // text={`${percentage}%`}
                                              value={this.state.foiremi}
                                              text={`${this.state.foiremi}%`}
                                              styles={buildStyles({
                                                textColor: "#4BCA81",
                                                pathColor: "#4BCA81",
                                                trailColor: "#d0cecd",
                                              })}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                  <div className="table-responsive">
                                    <table
                                      className="billed"
                                      cellSpacing="0"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="img/icon_bank.svg" />
                                          </td>
                                          <td>Loan Amount</td>
                                          <td className="text-right">
                                            <b>₹ {this.state.loan_amount}</b>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td>
                                            <span>Upfront Amount</span>
                                            <img src="img/icon_iicon.svg"  className="info-upfront"/>
                                            <div className="upfront-amt-pophover">
                                              <h5>Upfront Amount</h5>
                                              <div className="d-flex justify-content-between"><span>Advance EMI</span>  <span><b>₹ {this.state.advanceEmi}</b></span></div>
                                              <div className="d-flex justify-content-between"><span>DownPayment</span>  <span><b>₹ {this.state.downPayment}</b></span></div>
                                            </div>
                                          </td>
                                          <td className="text-right">
                                            ₹ {this.state.upfront_amount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td>
                                            <span>Monthly Amount </span>
                                            <span className="d-block">
                                              ( from{" "}
                                              {dateFormat(
                                                this.state.loan_startdate,
                                                "dd-mm-yyyy"
                                              )}{" "}
                                              )
                                            </span>
                                          </td>
                                          <td className="text-right">
                                            ₹ {this.state.emi_amount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="img/icon_money.svg" />
                                          </td>
                                          {/* <td>Total Payable <span>(APR {this.state.interest_rate}%)</span> <img src="img/icon_iicon.svg" /> </td> */}
                                          <td>Total Payable </td>
                                          <td className="text-right">
                                            <b>
                                              ₹ {Number(this.state.emi_amount)}
                                            </b>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  {/* <div className="d-block full_border"></div> */}
                                  {/* <h4>
                                    <b>Principle Moratorium</b>
                                    <span>( {this.state.moratorium_duration} months )</span></h4>
                                  <ul className="timeliner">
                                    <li className="started w40"><span></span>₹ {this.state.moratorium_amount} <label>per month</label></li>
                                    <li className="inProgress w60"><span></span>₹ {this.state.moratorium_amount} <label>per month</label></li>
                                    <li className="completed"><span></span></li>
                                  </ul> */}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="whiteboxed">
                                  <h6>Residential Stability</h6>
                                  <h4>
                                    <b>Addresses</b>
                                  </h4>
                                  <ul className="Addresses mt-3">
                                    {permenentAddress && (
                                      <li>
                                        <h5>Permanent Address</h5>
                                        <p>{permenentAddress}</p>
                                        <button className="btn btn-small">
                                          {this.state.resident_type
                                            ? this.state.resident_type
                                            : ""}
                                        </button>
                                      </li>
                                    )}

                                    {currentAddress && (
                                      <>
                                        <li>
                                          <h5>Current Address</h5>
                                          <p>{currentAddress}</p>
                                          {this.state.resident_type ? (
                                            <button className="btn btn-small">
                                              {" "}
                                              {this.state.resident_type}{" "}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      </>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                           
                          </div>

                          {/* nav douments tab */}
                          <div
                            className="tab-pane fade"
                            id="nav-documents"
                            role="tabpanel"
                            aria-labelledby="nav-documents-tab"
                          >
                            <div className="d-block pt-3 pb-2 pr-3 pl-3">
                              <div className="row">
                                {/* {
                                  alldoccount && alldoccount.length > 0 ?

                                    alldoccount.map((item, index) => ( */}
                                <div className="col-md-3">
                                  <ul
                                    className="nav nav-tabs"
                                    id="verticaltab"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#kyc"
                                        role="tab"
                                        aria-controls="kyc"
                                      >
                                        KYC Documents (
                                        {this.state.kycDoc &&
                                        this.state.kycDoc.length > 0
                                          ? this.state.kycDoc.length
                                          : "0"}
                                        ){" "}
                                        <a onClick={this.kycDocumentsDownload}>
                                          <img src="images/download.svg" />
                                        </a>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#bdocument"
                                        role="tab"
                                        aria-controls="bdocument"
                                        // onClick={this.generatePDF}
                                      >
                                        Bureau Document (
                                        {this.state.bureaurData &&
                                        this.state.bureaurData.length > 0
                                          ? this.state.bureaurData.length
                                          : "0"}
                                        )
                                        <a onClick={this.BureaoDocumentsDownload}>
                                          <img src="images/download.svg" />
                                        </a>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#fstatements"
                                        role="tab"
                                        aria-controls="fstatements"
                                      >
                                        Financial Statements (
                                        {this.state.bank_data &&
                                        this.state.bank_data.length > 0
                                          ? this.state.bank_data.length
                                          : "0"}
                                        ){" "}
                                        <a
                                          href={void 0}
                                          onClick={() =>
                                            this.financialDocumentsDownload()
                                          }
                                        >
                                          <img src="images/download.svg" />
                                        </a>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#aproofs"
                                        role="tab"
                                        aria-controls="aproofs"
                                        // onClick={this.generatePDF}
                                      >
                                        Address Proofs{" "}
                                        <span>
                                          (
                                          {this.state.addressProof &&
                                          this.state.addressProof.length > 0
                                            ? this.state.addressProof.length
                                            : "0"}
                                          ){" "}
                                        </span>{" "}
                                        <a onClick={this.AddressDocumentsDownload}>
                                          <img src="images/download.svg" />
                                        </a>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#odocuments"
                                        role="tab"
                                        aria-controls="odocuments"
                                        // onClick={this.generatePDF}
                                      >
                                        Other Documents{" "}
                                        <span>
                                          (
                                          {this.state.otherDocData &&
                                          this.state.otherDocData.length > 0
                                            ? this.state.otherDocData.length
                                            : "0"}
                                          )
                                        </span>{" "}
                                        <a onClick={this.OtherDocumentsDownload}>
                                          <img src="images/download.svg" />
                                        </a>
                                      </a>
                                    </li>
                                  </ul>
                                  <div className="row">
                                    <div className="col-md-12 text-right tbuttons">
                                      <button
                                        type="button"
                                        onClick={this.handleDownloadAll}
                                        className="btn btn-primary btn-s1"
                                      >
                                        <img src="images/download.svg" />
                                        Download All
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* )
                                    ) : "NO DATA COUNT"
                                } */}
                                <div className="col-md-9">
                                  <div className="tab-content">
                                    <div
                                      className="tab-pane active"
                                      id="kyc"
                                      role="tabpanel"
                                    >
                                      <h3 className="mb-3">
                                        <b>KYC Documents</b>
                                      </h3>
                                      <div className="row">
                                      {this.state.kycDoc &&
                                        this.state.kycDoc.length > 0 && (
                                          <>
                                            {this.state.kycDoc.map((item) => (
                                              <div className={item.FileType == "PDF"?"col-md-12":"col-md-6"}>
                                                <h6>
                                                  <b>
                                                    {
                                                      // item.base64[
                                                      //   "Document Type"
                                                      // ]
                                                      item.Document_Type__c
                                                    }
                                                  </b>{" "}
                                                  <a
                                                    href={
                                                      this.getDocTypeString(item.FileType) +
                                                      item.fileContent
                                                    }
                                                    download={!item.Title.includes(".") ? item.Title+"."+item.FileExtension  : item.Title}
                                                    className="ml-3"
                                                  >
                                                    <img src="images/download.svg" />
                                                  </a>
                                                </h6>
                                                <div className={`docimgs ${item.FileType == "PDF" && "statement"}`}>
                                                  {item.FileType == "PDF" ? (
                                                    <iframe
                                                      style={{
                                                        display: "block",
                                                        height: "100%",
                                                        width: "100%",
                                                      }}
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  ) : item.FileType == "TIF" ?
                                                   <div style={{color:'blue'}}> Image type is tip</div>
                                                  :(
                                                    <img
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                        </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="bdocument"
                                      role="tabpanel"
                                    >
                                      <h3 className="mb-3">
                                        <b>Bureau Document</b>
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-12 isdownload">
                                          {bureaurData &&
                                            bureaurData.length > 0 && (
                                              <>
                                                 <>
                                              <div className="col-md-12">
                                                <h6>
                                                  <b>
                                                     Bureau Reports
                                                  </b>{" "}
                                                  <a
                                                    href={
                                                      "data:text/html;base64," +
                                                      bureaurData[0].fileContent
                                                    }
                                                    download={"BureauReport.html"}
                                                    className="ml-3"
                                                  >
                                                    <img src="images/download.svg" />
                                                  </a>
                                                </h6>
                                                <div className={`docimgs statement`}>
                                                 
                                                    <iframe
                                                      style={{
                                                        display: "block",
                                                        height: "100%",
                                                        width: "100%",
                                                      }}
                                                      src={ "data:text/html;base64," +
                                                      bureaurData[0].fileContent}
                                                    />
                                                </div>
                                              </div>
                                         
                                          </>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="fstatements"
                                      role="tabpanel"
                                    >
                                      <h3 className="mb-3">
                                        <b>Financial Statements</b>
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-12 isdownload">
                                          {bank_data &&
                                            bank_data.length > 0 && (
                                        
                                             <>
                                            {bank_data.map((item) => (
                                              <div className={item.FileType == "PDF"?"col-md-12":"col-md-6"}>
                                                <h6>
                                                  <b>
                                                    {
                                                      // item.base64[
                                                      //   "Document Type"
                                                      // ]
                                                      item.Document_Type__c
                                                    }
                                                  </b>{" "}
                                                  <a
                                                    href={
                                                      this.getDocTypeString(item.FileType) +
                                                      item.fileContent
                                                    }
                                                    download={item.Title}
                                                    className="ml-3"
                                                  >
                                                    <img src="images/download.svg" />
                                                  </a>
                                                </h6>
                                                <div className={`docimgs ${item.FileType == "PDF" && "statement"}`}>
                                                  {item.FileType == "PDF" ? (
                                                    <iframe
                                                      style={{
                                                        display: "block",
                                                        height: "100%",
                                                        width: "100%",
                                                      }}
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  ) : item.FileType == "TIF" ?
                                                   <div style={{color:'blue'}}> Image type is tip</div>
                                                  :(
                                                    <img
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                          </>
                                             
                                            )}
                                        </div>
                                        <div className="col-md-12 isdownload ">
                                          {this.state.statementBase && (
                                            <>
                                              <h6
                                                href={void 0}
                                                onClick={() =>
                                                  this.financialDocumentsDownload()
                                                }
                                              >
                                                <b>Salary Slips</b>
                                                <img src="images/download.svg" />
                                              </h6>
                                              <div className="docimgs">
                                                <img
                                                  src={this.state.statementBase}
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="aproofs"
                                      role="tabpanel"
                                    >
                                      <h3 className="mb-3">
                                        <b>Address Proofs</b>
                                      </h3>
                                      <div className="row">
                                        {/* <div className="col-md-6 isdownload"> */}
                                          {this.state.addressProof &&
                                            this.state.addressProof.length >
                                              0 && (
                                                <>
                                                {this.state.addressProof.map((item) => (
                                                  <div className={item.FileType == "PDF"?"col-md-12":"col-md-6"}>
                                                    <h6>
                                                      <b>
                                                        {
                                                          // item.base64[
                                                          //   "Document Type"
                                                          // ]
                                                          item.Document_Type__c
                                                        }
                                                      </b>{" "}
                                                      <a
                                                        href={
                                                          this.getDocTypeString(item.FileType) +
                                                          item.fileContent
                                                        }
                                                        download={!item.Title.includes(".") ? item.Title+"."+item.FileExtension  : item.Title}
                                                        className="ml-3"
                                                      >
                                                        <img src="images/download.svg" />
                                                      </a>
                                                    </h6>
                                                    <div className={`docimgs ${item.FileType == "PDF" && "statement"}`}>
                                                      {item.FileType == "PDF" ? (
                                                        <iframe
                                                          style={{
                                                            display: "block",
                                                            height: "100%",
                                                            width: "100%",
                                                          }}
                                                          src={ this.getDocTypeString(item.FileType) +
                                                            item.fileContent}
                                                        />
                                                      ) : item.FileType == "TIF" ?
                                                       <div style={{color:'blue'}}> Image type is tip</div>
                                                      :(
                                                        <img
                                                          src={ this.getDocTypeString(item.FileType) +
                                                            item.fileContent}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                ))}
                                              </>
                                            )}
                                       
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="odocuments"
                                      role="tabpanel"
                                    >
                                      <h3 className="mb-3">
                                        <b>Other Documents</b>
                                      </h3>
                                      <div className="row">
                                      {this.state.otherDocData &&
                                        this.state.otherDocData?.length > 0 && (
                                          <>
                                            {this.state.otherDocData.map((item) => (
                                              <div className={item.FileType == "PDF"?"col-md-12":"col-md-6"}>
                                                <h6>
                                                  <b>
                                                    {
                                                      // item.base64[
                                                      //   "Document Type"
                                                      // ]
                                                      item.Document_Type__c
                                                    }
                                                  </b>{" "}
                                                  <a
                                                    href={
                                                      this.getDocTypeString(item.FileType) +
                                                      item.fileContent
                                                    }
                                                    download={!item.Title.includes(".") ? item.Title+"."+item.FileExtension  : item.Title}
                                                    className="ml-3"
                                                  >
                                                    <img src="images/download.svg" />
                                                  </a>
                                                </h6>
                                                <div className={`docimgs ${item.FileType == "PDF" && "statement"}`}>
                                                  {item.FileType == "PDF" ? (
                                                    <iframe
                                                      style={{
                                                        display: "block",
                                                        height: "100%",
                                                        width: "100%",
                                                      }}
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  ) : item.FileType == "TIF" ?
                                                   <div style={{color:'blue'}}> Image type is tip</div>
                                                  :(
                                                    <img
                                                      src={ this.getDocTypeString(item.FileType) +
                                                        item.fileContent}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* nav CAM tab */}
                          <div
                            className="tab-pane fade"
                            id="nav-cam"
                            role="tabpanel"
                            aria-labelledby="nav-cam-tab"
                          >
                              <div className="row  mb-2">
                              <div className="col-md-12">
                                <div className="whiteboxed">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <h6>Credit Factors</h6>
                                      <h4>
                                        <b>Bureau Details</b>
                                      </h4>
                                      {/* <div className="mt-4 position-relative">
                                        <div className="clock">
                                          <div className="handel" style={this.state.civil_score ? { "transform": "rotate(" + (300 / 900) * (this.state.civil_score) + "deg)" }
                                            : { "transform": "rotate(60deg)" }}></div>
                                          <div className="amount-counter">
                                            <h2 className="mb-2">{this.state.civil_score ? this.state.civil_score : 300}</h2>
                                            <p>BUREAU SCORE</p>
                                          </div>
                                        </div>
                                        <div className="min-score">300</div>
                                        <div className="max-score">900</div>
                                      </div> */}
                                    </div>
                                    <div className="col-md-8">
                                      <nav className="float-left">
                                        <div
                                          className="nav nav-tabs"
                                          id="nav-tab"
                                          role="tablist"
                                        >
                                          <a
                                            className="nav-item nav-link active"
                                            id="nav-bureau-tab"
                                            data-toggle="tab"
                                            href="#nav-bureau"
                                            role="tab"
                                            aria-controls="nav-bureau"
                                            aria-selected="true"
                                          >
                                            Soft Pull Experian
                                          </a>
                                          {/* <a
                                            className="nav-item nav-link"
                                            id="nav-bureau-tab-hard"
                                            data-toggle="tab"
                                            href="#nav-bureau-hard"
                                            role="tab"
                                            aria-controls="nav-bureau-hard"
                                            aria-selected="false"
                                          >
                                            Hard Pull Experian
                                          </a> */}
                                          <a
                                            className="nav-item nav-link"
                                            id="nav-liabilities-tab"
                                            data-toggle="tab"
                                            href="#nav-liabilities"
                                            role="tab"
                                            aria-controls="nav-liabilities"
                                            aria-selected="false"
                                          >
                                            Bureau Liabilities
                                          </a>
                                        </div>
                                      </nav>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12 pt-2">
                                      <div className="card">
                                        <div className="card-body">
                                          <div
                                            className="tab-content"
                                            id="nav-tabContent-1"
                                          >
                                            <div
                                              className="tab-pane fade active show"
                                              id="nav-bureau"
                                              role="tabpanel"
                                              aria-labelledby="nav-bureau-tab"
                                            >
                                              {bureauAnalysisData && bureauAnalysisData.length ? (
                                                bureauAnalysisData.map((item, index) => (
                                                  <div
                                                    className="row"
                                                    key={item}
                                                  >
                                                    <div className="col-md-4">
                                                      <div className="mt-1 position-relative">
                                                        <div
                                                          className="clock"
                                                          style={{ marginLeft: '12px' }}
                                                          
                                                        >
                                                          <div
                                                            className="handel"
                                                            style={
                                                              item.Bureau_Score
                                                                ? {
                                                                    transform:
                                                                      "rotate(" +
                                                                      (300 /
                                                                        900) *
                                                                        item.Bureau_Score +
                                                                      "deg)",
                                                                  }
                                                                : {
                                                                    transform:
                                                                      "rotate(60deg)",
                                                                  }
                                                            }
                                                          ></div>
                                                          <div className="amount-counter">
                                                            <h2 className="mb-2">
                                                              {item.Bureau_Score
                                                                ? item.Bureau_Score
                                                                : 300}
                                                            </h2>
                                                            <p>BUREAU SCORE</p>
                                                          </div>
                                                          <div className="min-score">
                                                            300
                                                          </div>
                                                          <div className="max-score">
                                                            900
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="row custmer-eligibility-cont">
                                                        <div className="col-md-12 mb-3 fw-600 fs-16">
                                                        Customer Eligibility
                                                        </div>

                                                        <ul className="col-md-12 listhwithcounts">
                                                            <li>
                                                            Requested Loan Amount
                                                              <span>
                                                                {customerEligibilityData.appliedLoanAmount ? `₹ ${customerEligibilityData.appliedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Unutilised Limit
                                                              <span>
                                                              {customerEligibilityData.unutilisedLimit ? `₹ ${customerEligibilityData.unutilisedLimit}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                      
                                                            <li className="fs-15 fw-500">
                                                            Income Details
                                                              <span className="fs-12 fw-400">
                                                                 total
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Income
                                                              <span>
                                                              {customerEligibilityData.income ? `₹ ${customerEligibilityData.income}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                            Other Income
                                                              <span>
                                                              {customerEligibilityData.otherIncome ? `₹ ${customerEligibilityData.otherIncome}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Income
                                                              <span>
                                                              {customerEligibilityData.totalIncomes ? `₹ ${customerEligibilityData.totalIncomes}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            FOIR ( {customerEligibilityData?.foir ? `${customerEligibilityData.foir} %` : "₹ 0"} )
                                                              <span>
                                                              {customerEligibilityData.totalFoir ? `₹ ${customerEligibilityData.totalFoir}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           </div>


                                                           <li className="fs-15 fw-500">
                                                           Liabilities
                                                              <span className="fs-12 fw-400">
                                                               
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Bureau Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalBureauLiabiliti ? `₹ ${customerEligibilityData.totalBureauLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Other Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalOtherLiabiliti ? `₹ ${customerEligibilityData.totalOtherLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalLiabilities ? `₹ ${Number(customerEligibilityData.totalLiabilities).toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           
                                                           </div>

                                                           <hr/>
                                                           <div className="ml-2">
                                                            <li>
                                                            Servicable EMI
                                                              <span>
                                                              {customerEligibilityData.servicableEMI ? `₹ ${customerEligibilityData.servicableEMI.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Multiplier
                                                              <span>
                                                              {customerEligibilityData.multiplier ? `₹ ${customerEligibilityData.multiplier}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Limit Approved
                                                              <span>
                                                              {customerEligibilityData.limitApproved ? `₹ ${customerEligibilityData.limitApproved}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Approved Loan Amount
                                                              <span>
                                                              {customerEligibilityData.approvedLoanAmount ? `₹ ${customerEligibilityData.approvedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                           </div>
                                                            </ul>

                                                      </div>
                                                      {/* <div className="">Add credit Ccore</div> */}
                                                      {/* <ul className="listhwithcounts">
                                                        <li>
                                                          Bank Accounts
                                                          <span>
                                                          
                                                            05
                                                          </span>
                                                        </li>
                                                        <li>
                                                          Overdues
                                                          <span>
                                                            
                                                            01
                                                          </span>
                                                        </li>
                                                        <li>
                                                          0 Balance Ac
                                                          <span>
                                                           
                                                            01
                                                          </span>
                                                        </li>
                                                      </ul> */}
                                                    </div>

                                                    <div className="col-md-8 pl-4">
                                                    
                                                      <h6 className="s1">
                                                        Borrower's Bureau
                                                        Analysis{" "}
                                                        <span>
                                                          Refreshed on:{" "}
                                                          { 
                                                            // item.Last_refreshed_on ? moment(item.Last_refreshed_on).format("Do MMM YYYY, HH A"):"-"
                                                            item.Last_refreshed_on ? moment(new Date(item.Last_refreshed_on)).format("MM/DD/YYYY, hh:mm A") :"-"
                                                          }
                                                        </span>
                                                      </h6>

                                                      <div className="row">
                                                        <div className="col-md-12">
                                                          <ul className="listhwithcounts">
                                                            <li>
                                                              No. of Tradelines
                                                              with 0+ DPD in
                                                              last 3 months
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_0_DPD_in_last_3_months
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              No. of Tradelines
                                                              with 0+ DPD in
                                                              last 12 months
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_0_DPD_in_last_12_months
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              No. of Tradelines
                                                              with Overdue
                                                              Amount {">"} 0
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_Overdue_greater_than_zero
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              No. of tradelines
                                                              with Wilful
                                                              Default/Settlement/Suit
                                                              Filed/SMA/LSS/DBT
                                                              Accounts
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_Wilful_Settt_Fault_Dbt_Lss_sma
                                                                }
                                                              </span>
                                                            </li>
                                                         
                                                        
                                                         
                                                            <li>
                                                              No. of Tradelines
                                                              with 90+ DPD in
                                                              last 12 months
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_90_DPD_in_last_12_months
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              No. of Tradelines
                                                              with 0+ DPD in
                                                              last 3 months
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_30_DPD_in_last_3_months
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              No. of Tradelines
                                                              with Overdue
                                                              Amount{" >"} 5000
                                                              <span>
                                                                {
                                                                  item.No_of_Tradelines_with_Overdue_greater_than_5000
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              Age in bureau (in
                                                              months)
                                                              <span>
                                                                {
                                                                  item.Age_in_bureau
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                      
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <div className="mt-1 position-relative">
                                                      <div className="clock" style={{ marginLeft: '12px' }}>
                                                          <div className="handel" 
                                                          style={this.state.civil_score ? { "transform": "rotate(" + (300 / 900) * this.state.civil_score + "deg)" }
                                                            : { "transform": "rotate(60deg)" }}></div>
                                                          <div className="amount-counter">
                                                            <h2 className="mb-2">{this.state.civil_score ? this.state.civil_score : 0}</h2>
                                                            <p>BUREAU SCORE</p>
                                                          </div>
                                                          <div className="min-score">300</div>
                                                          <div className="max-score">900</div>
                                                        </div>
                                                    </div>

                                                    <div className="row custmer-eligibility-cont">
                                                        <div className="col-md-12 mb-3 fw-600 fs-16">
                                                        Customer Eligibility
                                                        </div>

                                                        <ul className="col-md-12 listhwithcounts">
                                                            <li>
                                                            Requested Loan Amount
                                                              <span>
                                                                {customerEligibilityData.appliedLoanAmount ? `₹ ${customerEligibilityData.appliedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Unutilised Limit
                                                              <span>
                                                              {customerEligibilityData.unutilisedLimit ? `₹ ${customerEligibilityData.unutilisedLimit}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                      
                                                            <li className="fs-15 fw-500">
                                                            Income Details
                                                              <span className="fs-12 fw-400">
                                                                 total
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Income
                                                              <span>
                                                              {customerEligibilityData.income ? `₹ ${customerEligibilityData.income}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                            Other Income
                                                              <span>
                                                              {customerEligibilityData.otherIncome ? `₹ ${customerEligibilityData.otherIncome}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Income
                                                              <span>
                                                              {customerEligibilityData.totalIncomes ? `₹ ${customerEligibilityData.totalIncomes}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            FOIR ( {customerEligibilityData?.foir ? `${customerEligibilityData.foir} %` : "₹ 0"} )
                                                              <span>
                                                              {customerEligibilityData.totalFoir ? `₹ ${customerEligibilityData.totalFoir}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           </div>


                                                           <li className="fs-15 fw-500">
                                                           Liabilities
                                                              <span className="fs-12 fw-400">
                                                               
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Bureau Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalBureauLiabiliti ? `₹ ${customerEligibilityData.totalBureauLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Other Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalOtherLiabiliti ? `₹ ${customerEligibilityData.totalOtherLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalLiabilities ? `₹ ${Number(customerEligibilityData.totalLiabilities).toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           
                                                           </div>

                                                           <hr/>
                                                           <div className="ml-2">
                                                            <li>
                                                            Servicable EMI
                                                              <span>
                                                              {customerEligibilityData.servicableEMI ? `₹ ${customerEligibilityData.servicableEMI.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Multiplier
                                                              <span>
                                                              {customerEligibilityData.multiplier ? `₹ ${customerEligibilityData.multiplier}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Limit Approved
                                                              <span>
                                                              {customerEligibilityData.limitApproved ? `₹ ${customerEligibilityData.limitApproved}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Approved Loan Amount
                                                              <span>
                                                              {customerEligibilityData.approvedLoanAmount ? `₹ ${customerEligibilityData.approvedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                           </div>
                                                            </ul>

                                                      </div>
                                                    {/* <div className="">Add credit Ccore</div> */}
                                                    {/* <ul className="listhwithcounts">
                                                      <li>
                                                        Bank Accounts
                                                        <span>05</span>
                                                      </li>
                                                      <li>
                                                        Overdues<span>01</span>
                                                      </li>
                                                      <li>
                                                        0 Balance Ac
                                                        <span>01</span>
                                                      </li>
                                                    </ul> */}
                                                  </div>
                                                  <div className="col-md-8 pl-4">
                                
                                                    <h6 className="s1">
                                                      Borrower's Bureau Analysis{" "}
                                                      <span>
                                                        Refreshed on: -{" "}
                                                      </span>
                                                    </h6>
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <ul className="listhwithcounts">
                                                          <li>
                                                            No. of Tradelines
                                                            with 0+ DPD in last
                                                            3 months
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            No. of Tradelines
                                                            with 0+ DPD in last
                                                            12 months
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            No. of Tradelines
                                                            with Overdue Amount{" "}
                                                            {">"} 0
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            No. of tradelines
                                                            with Wilful
                                                            Default/Settlement/Suit
                                                            Filed/SMA/LSS/DBT
                                                            Accounts
                                                            <span>-</span>
                                                          </li>
                                                          
                                                          <li>
                                                            No. of Tradelines
                                                            with 90+ DPD in last
                                                            12 months
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            No. of Tradelines
                                                            with 0+ DPD in last
                                                            3 months
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            No. of Tradelines
                                                            with Overdue Amount
                                                            {" >"} 5000
                                                            <span>-</span>
                                                          </li>
                                                          <li>
                                                            Age in bureau (in
                                                            months)
                                                            <span>-</span>
                                                          </li>
                                                       
                                                        </ul>
                                                      </div>
                                                      {/* <div className="col-md-6">
                                                      
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>

                                            <div
                                              className="tab-pane fade"
                                              id="nav-liabilities"
                                              role="tabpanel"
                                              aria-labelledby="nav-liabilities-tab"
                                            >
                                               <div className="row">
                                               <div className="col-md-4">
                                                   

                                                   <div className="w-100 custmer-eligibility-cont">
                                                       <div className="mb-3 fw-600 fs-16">
                                                       Customer Eligibility
                                                       </div>

                                                       <ul className="col-md-12 listhwithcounts">
                                                            <li>
                                                            Requested Loan Amount
                                                              <span>
                                                                {customerEligibilityData.appliedLoanAmount ? `₹ ${customerEligibilityData.appliedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Unutilised Limit
                                                              <span>
                                                              {customerEligibilityData.unutilisedLimit ? `₹ ${customerEligibilityData.unutilisedLimit}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                      
                                                            <li className="fs-15 fw-500">
                                                            Income Details
                                                              <span className="fs-12 fw-400">
                                                                 total
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Income
                                                              <span>
                                                              {customerEligibilityData.income ? `₹ ${customerEligibilityData.income}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                            Other Income
                                                              <span>
                                                              {customerEligibilityData.otherIncome ? `₹ ${customerEligibilityData.otherIncome}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Income
                                                              <span>
                                                              {customerEligibilityData.totalIncomes ? `₹ ${customerEligibilityData.totalIncomes}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            FOIR ( {customerEligibilityData?.foir ? `${customerEligibilityData.foir} %` : "₹ 0"} )
                                                              <span>
                                                              {customerEligibilityData.totalFoir ? `₹ ${customerEligibilityData.totalFoir}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           </div>


                                                           <li className="fs-15 fw-500">
                                                           Liabilities
                                                              <span className="fs-12 fw-400">
                                                               
                                                              </span>
                                                              
                                                            </li>
                                                           <div className="ml-2">
                                                            <li>
                                                            Bureau Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalBureauLiabiliti ? `₹ ${customerEligibilityData.totalBureauLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Other Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalOtherLiabiliti ? `₹ ${customerEligibilityData.totalOtherLiabiliti.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                            <li>
                                                            Total Liabilities
                                                              <span>
                                                              {customerEligibilityData.totalLiabilities ? `₹ ${Number(customerEligibilityData.totalLiabilities).toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           
                                                           </div>

                                                           <hr/>
                                                           <div className="ml-2">
                                                            <li>
                                                            Servicable EMI
                                                              <span>
                                                              {customerEligibilityData.servicableEMI ? `₹ ${customerEligibilityData.servicableEMI.toFixed(2)}` : "₹ 0"}
                                                              </span>
                                                              
                                                            </li>
                                                           <li>
                                                           Multiplier
                                                              <span>
                                                              {customerEligibilityData.multiplier ? `₹ ${customerEligibilityData.multiplier}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Limit Approved
                                                              <span>
                                                              {customerEligibilityData.limitApproved ? `₹ ${customerEligibilityData.limitApproved}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                            Approved Loan Amount
                                                              <span>
                                                              {customerEligibilityData.approvedLoanAmount ? `₹ ${customerEligibilityData.approvedLoanAmount}` : "₹ 0"}
                                                              </span>
                                                            </li>
                                                           </div>
                                                            </ul>

                                                     </div>
                                                  
                                                 </div>
                                              {bureauLiabilitiesData &&
                                              bureauLiabilitiesData.length >
                                                0 ? (                                                
                                                  <div className="col-md-8 pl-4">
                                                  <div className="table-responsive">
                                                  <table
                                                    className="liabilities"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>
                                                          Active Tradelines
                                                        </th>
                                                        <th>Tradelines</th>
                                                        <th>O/s Balance</th>
                                                        <th>EMI in Bureau</th>
                                                        <th>Imputed EMI</th>
                                                        <th>Obligated EMI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                     {
                                                      bureauLiabilitiesData.map(item => {
                                                        return(
                                                          <tr style={item.isBold? {fontWeight:"bold"}:{}}>
                                                          <td>
                                                            {item.Active_Tradelines__c
                                                              ? item.Active_Tradelines__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                          {item.Tradelines__c
                                                              ? item.Tradelines__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.O_s_Balance__c
                                                              ? item.O_s_Balance__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.EMI_in_Bureau__c
                                                              ? item.EMI_in_Bureau__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.Imputed_EMI__c
                                                              ? item.Imputed_EMI__c.toFixed(2)
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.Obligated_EMI__c
                                                              ? item.Obligated_EMI__c.toFixed(2)
                                                              : "-"}
                                                          </td>
                                                        </tr>

                                                        )
                                                      })
                                                     }
                                                      {/* <tr>
                                                      <td>Education Loan</td>
                                                      <td>3</td>
                                                      <td>₹ 35 000</td>
                                                      <td>₹ 7 000</td>
                                                      <td>₹ 7 000</td>
                                                      <td>₹ 7 000</td>
                                                    </tr>
                                                    <tr>
                                                      <th>Total</th>
                                                      <th>4</th>
                                                      <th>₹ 70 000</th>
                                                      <th>₹ 12 000</th>
                                                      <th>₹ 12 000</th>
                                                      <th>₹ 12 000</th>
                                                    </tr> */}
                                                    </tbody>
                                                  </table>
                                                </div>
                                                  </div>
                                              
                                                
                                              ) : (
                                                <div className="col-md-8 pl-4">
                                                <div className="table-responsive">
                                                  <table
                                                    className="liabilities"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>
                                                          Active Tradelines
                                                        </th>
                                                        <th>Tradelines</th>
                                                        <th>O/s Balance</th>
                                                        <th>EMI in Bureau</th>
                                                        <th>Imputed EMI</th>
                                                        <th>Obligated EMI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className="no-liability-text" colSpan={6}>No bureau liabilities data</td>
                                                      </tr>
                                                     
                                                    </tbody>
                                                  </table>
                                                </div>
                                                </div>
                                              )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                             <div className="row mt-3">
                              <div className="col-md-8">
                              <h3>Addresses</h3>
                              <div className="row">
                              { this.state.addressList?.length > 0  && 
                                this.state.addressList.map(ele => {
                                  return (
                                    <div className="col-md-6 mb-4 mb-3">
                                  <div className="whiteboxed h-mcontent">
                                    <h5>{ele.Address_Type__c || "-"} </h5>
                                   <h4>(Source : {ele.Address_Source__c})</h4>
                                   <p className="my-3 fs-14">{ele.Consolidated_Address__c}</p>
                                   <div className="add-res-type">Residence Type :</div>
                                  </div>
                                  </div>
                                  )
                                })
                                }
                              </div>
                              </div>
                              
                              <div className="col-md-4">
                                  <h3>Bank Details</h3>
                                <div className="whiteboxed h-mcontent">
                                  
                                  <ul className="row Address mt-3">
                                      <li className="col-md-6 mb-4">
                                        <p className="add-det-label">Account Holder</p>
                                        <h5 className="add-det-value">{this.state?.BankDeatils?.Bank_Account_Holder_Name__c || "-"}</h5>
                                      </li>
                                      <li className="col-md-6 mb-4">
                                        <p className="add-det-label">Account Type</p>
                                        <h5 className="add-det-value">{this.state?.BankDeatils?.Account_Type__c || "-"}</h5>
                                      </li>
                                      <li className="col-md-6 mb-4">
                                        <p className="add-det-label">IFSC Code</p>
                                        <h5 className="add-det-value">{this.state?.BankDeatils?.IFSC__c || "-"}</h5>
                                      </li>
                                      <li className="col-md-6 mb-4">
                                        <p className="add-det-label">Account</p>
                                        <h5 className="add-det-value">{this.state?.BankDeatils?.Account_Number__c || "-"}</h5>
                                      </li>
                                      <li className="col-md-12 mb-4">
                                        <p className="add-det-label">Bank Name</p>
                                        <h5 className="add-det-value">{this.state?.BankDeatils?.Bank_Name__c || "-"}</h5>
                                      </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          
                          </div>

                          {/* Nav Repayment tab */}

                          <div
                            className="tab-pane fade"
                            id="nav-repayment"
                            role="tabpanel"
                            aria-labelledby="nav-repayment-tab"
                          >
                              <div className="row mb-2">
                              <div className="col-md-12">
                                <div className="whiteboxed">
                                  <div className="row">
                                    <div className="col-md-12">
                                    <div className="table-responsive">
                                                  <table
                                                    className="liabilities w-full"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>
                                                        EMI No
                                                        </th>
                                                        <th>Due Date</th>
                                                        <th>EMI Status</th>
                                                        <th>EMI Amount</th>
                                                        <th>Interest Amount</th>
                                                        <th>Principal Portion</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                     {
                                                     repaymentScheduleList?.length ? repaymentScheduleList.map(item => {
                                                        return(
                                                          <tr style={item.isBold? {fontWeight:"bold"}:{}}>
                                                          <td>
                                                            {item.EMI_No__c
                                                              ? item.EMI_No__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                          {item.Due_Date__c
                                                              ? item.Due_Date__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            
                                                            {item.EMI_status__c
                                                              ? item.EMI_status__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.LMS_EMI_Amount__c
                                                              ? item.LMS_EMI_Amount__c
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.Interest_Amount__c
                                                              ? item.Interest_Amount__c.toFixed(2)
                                                              : "-"}
                                                          </td>
                                                          <td>
                                                            ₹{" "}
                                                            {item.Principal_Portion__c
                                                              ? item.Principal_Portion__c
                                                              : "-"}
                                                          </td>
                                                        </tr>
                                                        )
                                                      })  : 
                                                      <tr>
                                                      <td className="no-liability-text" colSpan={6}>No data Found</td>
                                                    </tr>
                                                     } 

                                                    </tbody>
                                                  </table>
                                                </div>
                                    </div>
                                  
                                  
                                  </div>

                                 
                                </div>
                              </div>
                            </div>

                          </div>


                          




                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-right tbuttons pt-3 pb-3">
                        {this.state.prevDet && (
                          <button
                            type="button"
                            onClick={() => this.openLeads(this.state.prevDet)}
                            className="btn btn-primary mr-4"
                          >
                            <img src="img/icon_pcase.svg" /> Previous Case
                          </button>
                        )}
                        {this.state.nextDet && (
                          <button
                            type="button"
                            onClick={() => this.openLeads(this.state.nextDet)}
                            className="btn btn-primary"
                          >
                            Next Case{" "}
                            <img
                              className="alignedright"
                              src="img/icon_ncase.svg"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </div>
            {/* End of Main Content */}
          </div>
          {/* End of Content Wrapper */}
        </div>

        <div
          className="modal fade documentspopup"
          id="docModel"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex col-md-12">
                  <div className="col-md-4">
                    <img src="img/icon_tick.svg" /> Verified by Eduvanz
                  </div>
                  <div className="col-md-4 text-center">
                    <h5>
                      <b>{this.state.modalTitle}</b>
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body" style={{ paddingBottom: "100px" }}>
                <div className="row justify-content-center align-items-center">
                  {this.state.viewIs == APP ? (
                    this.state.imageObj && this.state.imageObj.length > 0 ? (
                      this.state.imageObj.map((data, index) => {
                        let margin = index > 1 ? "100px" : "0px";
                        return (
                          <div
                            className="mr-2"
                            style={{
                              position: "relative",
                              width: "48%",
                              height: "350px",
                              marginTop: margin,
                            }}
                          >
                            {this.state.imageTypeis == "PDF" ? (
                              <iframe
                                src={data}
                                width="100%"
                                height="100%"
                              ></iframe>
                            ) : (
                              <img
                                src={data}
                                style={{ width: "98%", height: "100%" }}
                              />
                            )}
                            <a href={data} download={"image.jpeg"}>
                              <button
                                className="btn btn-primary mt-4 mr-2"
                                style={{
                                  background: "#00000040",
                                  float: "right",
                                }}
                              >
                                <img src="img/icon_Download.svg" /> Download
                              </button>
                            </a>
                          </div>
                        );
                      })
                    ) : this.state.apiCall == PRO ? (
                      "Loading image..."
                    ) : (
                      "No document available"
                    )
                  ) : (
                    <div
                      className=" pt-5 pb-5 pl-5 pr-5"
                      style={{ position: "relative" }}
                    >
                      <img src={this.state.showDocument} />
                    </div>
                  )}
                </div>
              </div>
              {this.state.viewIs == DOC && (
                <div className="modal-footer">
                  <a href={this.state.showDocument} download={"image.jpeg"}>
                    <button className="btn btn-primary mr-4">
                      <img src="img/icon_Download.svg" /> Download
                    </button>
                  </a>
                  <button className="btn btn-primary mr-4">
                    <img src="img/icon_pcase.svg" /> Previous
                  </button>
                  <button className="btn btn-primary">
                    Next{" "}
                    <img className="alignedright" src="img/icon_ncase.svg" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade documentspopup"
          id="doc_type_Model"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex col-md-12">
                  <div className="col-md-4">
                    <img src="img/icon_tick.svg" /> Verified by Eduvanz
                  </div>
                  <div className="col-md-4 text-center">
                    <h5>
                      <b>{this.state.modalTitle}</b>
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body" style={{ paddingBottom: "100px" }}>
                <div className="row justify-content-center align-items-center">
                  {this.state.show_docType.length > 0 &&
                  this.state.show_docType == "bank_statement_list" ? (
                    <>
                      {this.state.bank_statement_list.map((data, index) => {
                        let margin = index > 1 ? "100px" : "0px";
                        return (
                          <div
                            className="mb-8"
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "350px",
                              marginTop: margin,
                            }}
                          >
                            {data.filetype == "PDF" ? (
                              <>
                                <iframe
                                  src={
                                    "data:application/pdf;base64," +
                                    data.base64.base64
                                  }
                                  width="100%"
                                  height="100%"
                                ></iframe>
                                <a
                                  href={
                                    "data:application/pdf;base64," +
                                    data.base64.base64
                                  }
                                  download={"bank_statement.pdf"}
                                >
                                  <button
                                    className="btn btn-primary mt-4 mr-2"
                                    style={{
                                      background: "#00000040",
                                      float: "right",
                                    }}
                                  >
                                    <img src="img/icon_Download.svg" /> Download
                                  </button>
                                </a>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    "data:image/jpg;base64," +
                                    data.base64.base64
                                  }
                                  style={{ width: "98%", height: "100%" }}
                                />
                                <a
                                  href={
                                    "data:image/jpg;base64," +
                                    data.base64.base64
                                  }
                                  download={"image.jpeg"}
                                >
                                  <button
                                    className="btn btn-primary mt-4 mr-2"
                                    style={{
                                      background: "#00000040",
                                      float: "right",
                                    }}
                                  >
                                    <img src="img/icon_Download.svg" /> Download
                                  </button>
                                </a>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {this.state.salary_slip_list.map((data, index) => {
                        let margin = index > 1 ? "100px" : "0px";
                        return (
                          <div
                            className="mb-8"
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "350px",
                              marginTop: margin,
                            }}
                          >
                            {data.filetype == "PDF" ? (
                              <>
                                <iframe
                                  src={
                                    "data:application/pdf;base64," +
                                    data.base64.base64
                                  }
                                  width="100%"
                                  height="100%"
                                ></iframe>
                                <a
                                  href={
                                    "data:application/pdf;base64," +
                                    data.base64.base64
                                  }
                                  download={"bank_statement.pdf"}
                                >
                                  <button
                                    className="btn btn-primary mt-4 mr-2"
                                    style={{
                                      background: "#00000040",
                                      float: "right",
                                    }}
                                  >
                                    <img src="img/icon_Download.svg" /> Download
                                  </button>
                                </a>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    "data:image/jpg;base64," +
                                    data.base64.base64
                                  }
                                  style={{ width: "98%", height: "100%" }}
                                />
                                <a
                                  href={
                                    "data:image/jpg;base64," +
                                    data.base64.base64
                                  }
                                  download={"image.jpeg"}
                                >
                                  <button
                                    className="btn btn-primary mt-4 mr-2"
                                    style={{
                                      background: "#00000040",
                                      float: "right",
                                    }}
                                  >
                                    <img src="img/icon_Download.svg" /> Download
                                  </button>
                                </a>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}

                  {/* {
                    this.state.viewIs == APP ?
                      this.state.imageObj && this.state.imageObj.length > 0 ?
                        this.state.imageObj.map((data, index) => {
                          let margin = index > 1 ? "100px" : "0px";
                          return (
                            <div className="mr-2" style={{ position: "relative", width: "48%", height: "350px", marginTop: margin }}>
                              {
                                this.state.imageTypeis == "PDF" ?
                                  <iframe src={data} width="100%" height="100%"></iframe>
                                  :
                                  <img src={data} style={{ width: "98%", height: "100%" }} />
                              }
                              <a href={data} download={"image.jpeg"}>
                                <button className="btn btn-primary mt-4 mr-2" style={{ background: "#00000040", float: "right" }}><img src="img/icon_Download.svg" /> Download</button>
                              </a>
                            </div>
                          )
                        })
                        : this.state.apiCall == PRO ? "Loading image..." : "No document available"
                      :
                      <div className=" pt-5 pb-5 pl-5 pr-5" style={{ position: "relative" }}>
                        <img src={this.state.showDocument} />
                      </div>
                  } */}
                </div>
              </div>
              {
                // this.state.viewIs == DOC &&
                <div className="modal-footer">
                  {/* <a href={this.state.showDocument} download={"image.jpeg"}>
                    <button className="btn btn-primary mr-4"><img src="img/icon_Download.svg" /> Download</button>
                  </a>
                  <button className="btn btn-primary mr-4"><img src="img/icon_pcase.svg" /> Previous</button>
                  <button className="btn btn-primary">Next <img className="alignedright" src="img/icon_ncase.svg" /></button> */}
                </div>
              }
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="buerau_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl w-100" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-header pl-4 pb-0">Bureau Report</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <HtmlIframe html_code={bureaurData} /> */}
                <div className={`docimgs statement`}>
                                                 
                                                 <iframe
                                                   style={{
                                                     display: "block",
                                                     height: "400px",
                                                     width: "100%",
                                                   }}
                                                   src={ "data:text/html;base64," +
                                                   bureaurData[0]?.fileContent}
                                                 />
                                             </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoading, isLoggedIn, salesForceToken, user_id, user_sfid } =
    state.auth;
  const { lead_id, lead_profile, opp_sfid, repayment } = state.user;
  const { open_raise_query, closeRaiseQuery } = state.model;
  const { message } = state.message;
  return {
    isLoggedIn,
    isLoading,
    message,
    lead_id,
    opp_sfid,
    repayment,
    lead_profile,
    salesForceToken,
    open_raise_query,
    user_id,
    user_sfid,
  };
}

export default connect(mapStateToProps)(LeadDetails);
