import React,{ Component } from "react";
import { Helmet } from "react-helmet";
import {Redirect} from 'react-router-dom'
import Headers from "../common/header";
import Sidebar from "../common/sidebar";
import { connect } from "react-redux";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { BsCalendar2Week } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { getReportData } from "../actions/users";


const ALL = "ALL"
const TODAY = "TODAY";
const THISWEEK = "THIS-WEEK";
const THISMONTH = "THIS-MONTH"
const CUSTOM = "CUSTOM";



class AllReports extends Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
         range:TODAY,
         fromDate:new Date(),
         toDate:new Date()

      };
      this.fromdatePickerRef = React.createRef();
      this.todatePickerRef = React.createRef();
    }

    selectReportRange = (value) => {
        this.setState({range:value})
    }

    focusDatePicker = (val) => {
      if(val === 1 ){
        if (this.fromdatePickerRef.current) {
          this.fromdatePickerRef.current.setFocus();
        }
  
      }else{
        if (this.todatePickerRef.current) {
          this.todatePickerRef.current.setFocus();
        }
  
      }
          };

     handleDownloadReport = () => {
         const {range,fromDate,toDate} = this.state;
         let data = {lenderId:localStorage.getItem('lender_sf_id'),
         date_range:range,
         start_date:"",
         end_date:""
        };

         if(range === CUSTOM){
           data = {...data,start_date:new Date(fromDate).toJSON().slice(0,10),end_date:new Date(toDate).toJSON().slice(0,10)}
         }
         this.props.dispatch(getReportData(data)).then(response => {

           const url = window.URL.createObjectURL(new Blob([response], { type: `text/csv` }));
           const link = document.createElement("a");
           link.href = url;     
           const currentTimestamp = Math.floor(Date.now() / 1000);
           const filename=`disbursement_report_${currentTimestamp}.csv`
           link.setAttribute("download", filename);
           // Changed line below
           document.body.appendChild(link); // This will add your link to the DOM
           // ------------------
           link.click();
           window.URL.revokeObjectURL(url);
           link.remove(); // This will remove the link from DOM after completion of the pr

          // if(response.status === "success"){
          //   //  handle suceess   
          // }else{
          //   // error
          // }
       
          })

        //  return `https://borrow-uat.wizr.in/api/v1/lenderui/disbursedReport?lender_id=${data.lenderId}&date_range=${data.date_range}&start_date=${data.start_date}&end_date=${data.end_date}`
        }

      
    
    render() {
       const {user_id,isLoading,Loading} = this.props;
       const {range,fromDate,toDate} = this.state;
        if (!user_id) {
          return <Redirect to="/login" />
        }
    
        return (
          <>
            <Helmet>
              <title>Reports</title>
            </Helmet>
            {isLoading ? (
              <div className="loading">Loading&#8230;</div>
            ) : ''}
            {Loading ? (
              <div className="loading">Loading&#8230;</div>) : ''}
            <div id="wrapper">
              <Sidebar />
              {/* Content Wrapper */}
              <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                  {/* Topbar */}
                  <Headers
                    title={"Reports"}
                    isSearchEnable={false}
                    // dispatch={this.props.dispatch}
                    // keyword={this.state.global_keyword}
                    // getLeadsData = {this.getLeadsData}
                    // handleSearchData = {this.handleSearchData}
                  />
    
        
                 
                  <div className="container-fluid">
                    <div className="row">

                      <div className="col-md-12 pt-2 pr-4">
                      <h3 className="report-title mb-3">Disbursement Report</h3>
                      </div>
                     
                      <div className="col-md-12">
                      <div className="nav nav-tabs report_frequency_tabs" id="nav-tab" role="tablist">
                          <a
                            className={`nav-item report-nav nav-link active`}
                            id="daily-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="daily"
                            aria-selected="true"
                            onClick={() => this.selectReportRange(TODAY)}
                          >
                             Today

                          </a>
                          <a
                            className={`nav-item report-nav nav-link`}
                            id="weekly-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="weekly"
                            aria-selected="false"
                            onClick={() => this.selectReportRange(THISWEEK)}
                          >
                            This Week

                          </a>
                          <a
                            className={`nav-item report-nav nav-link`}
                            id="monthly-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="monthly"
                            aria-selected="false"
                            onClick={() => this.selectReportRange(THISMONTH)}
                          >
                            This Month

                          </a>
                          
                            
                            <a
                              className={`nav-item report-nav nav-link`}
                              id="custom-tab"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="custom"
                              aria-selected="false"
                              onClick={() => this.selectReportRange(CUSTOM)}
                            >
                              Custom
                            </a>
                        </div>
                      </div>

                     {range === CUSTOM && 
                     <div className='col-8 d-flex mt-3'>
                                  <div className="date-container mr-5">
                                    <label className="picker-labels">FromDate</label>
                                  <DatePicker 
                                  ref={this.fromdatePickerRef}
                                  wrapperClassName="react-picker" 
                                  selected={fromDate} 
                                  onChange={(date) => this.setState({fromDate:date})} 
                                  maxDate={new Date()}
                                  />
                                     <span className="calender-icon"  onClick={() => this.focusDatePicker(1)}  >
          <BsCalendar2Week />
        </span> 
                                  </div>
                                  <div className="date-container">
                                    <label className="picker-labels">toDate</label>
                                  <DatePicker 
                                  ref={this.todatePickerRef}
                                  wrapperClassName="react-picker" 
                                  selected={toDate} 
                                  onChange={(date) => this.setState({toDate:date})} 
                                  maxDate={new Date()}
                                  
                                  />
                                     <span className="calender-icon"  onClick={() => this.focusDatePicker(2)}  >
          <BsCalendar2Week />
        </span> 
                                  </div>
                               
                              </div>}

                              <div className="col-md-12 pt-2 pr-4">
                        <div className="report-down-btn mt-3" onClick={this.handleDownloadReport}>
                        <FiDownload /> Download
                  </div>
                       
                      </div>

                    </div>
                  </div>
                
                </div>
              
              </div>
              
            </div>

          </>
        );
      }
    }
    
    function mapStateToProps(state) {
      const { isLoading,user_id, user_sfid } = state.auth;
  const { Loading} = state.user;

      return {
        isLoading,
        user_sfid,
        user_id, 
        Loading
      };
    }
    
    export default connect(mapStateToProps)(AllReports);
