import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { checkOtp, salesForceLogin, mobileLogin, updateUserId,resendOtp } from "../actions/auth";
import { setMobileNo } from "../actions/users";

const style = {
  backgroundImage: "url(" + "images/background.png" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minHeight: 500
}

class VerifyOtp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5:"",
      otp6:"",
      isSuccess: true,
      errorMsg: '',
      timer: '00:30',
      viewResend: false,
      showResendOtp: true,
      resendOtpSuccess: '',
      showResendOtpSuccess: false,
      email:this.props.email,
      isUserAuthenticated:this.props.isUserAuthenticated,
      showTimer:true,
      showOtpSuccess:false,
      otpSuccessMessage:""

    };
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.textInput4 = React.createRef();
    this.textInput5 = React.createRef();
    this.textInput6 = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.startTimer = this.startTimer.bind(this);
    if(this.props.email == null || this.props.email === ''){
      this.props.history.push('/login')
    }
  }

  switchTab=(val)=>{
    this.props.handleTab(val)
    this.props.history.push("/login")
  }

  componentDidMount() {
    this.startTimer();
    this.handleClearMeaage();
  }

  handleClearMeaage = () => {
    // this.props.dispatch(clearMessage());
  }

  startTimer() {
    var presentTime = this.state.timer;
    var timeArray = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = this.checkSecond((timeArray[1] - 1));
    if (s == 59) { m = m - 1 }
    if (m < 0) {
      return
    }
    if (m === '00' && s === '00') {
      this.setState({ showTimer: false});
    }
    this.setState({ timer: m + ":" + s });
    setTimeout(this.startTimer, 1000);
  }

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) { sec = "0" + sec };
    if (sec < 0) { sec = "59" };
    return sec;
  }

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value, isSuccess: true, errorMsg: '' });
    if (value1 === 'otp6') {
      const givenOtp = parseInt(this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + event.target.value);
      if(String(givenOtp)?.length === 6){
        this.handleSubmit(event.target.value);
      }
    }
  }

  inputfocus = (elmnt, getvalue) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      //const next = elmnt.target.tabIndex - 2;
      const next = elmnt.target.tabIndex;
      this.backwardFocus(next);
      /* if (next > -1) {
        
        elmnt.target.form.elements[next].focus()
      } */
    }
    else {
      const pattern = /^[0-9]$/;
      if (pattern.test(elmnt.target.value)) {
        const next = elmnt.target.tabIndex;
        if (next < 7) {
          this.forwardFocus(next);
          //  elmnt.target.form.elements[next].focus()
        }
      } else {
        this.setState({ [getvalue]: '' });
        document.getElementById(getvalue).value = '';
      }
    }

  }

  handleSubmit(value) {
    let lastOtpDigit = value?value:this.state.otp6;
    const givenOtp =  parseInt(this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + lastOtpDigit);
    const { dispatch, history, log, onBording } = this.props;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if(String(givenOtp)?.length !== 6){
      this.setState({isSuccess:false,errorMsg:'Enter all 6 digits to proceed.'})
    }
    if (pattern.test(this.state.otp1) && pattern.test(this.state.otp3) && pattern.test(lastOtpDigit) && pattern.test(this.state.otp2) && pattern.test(this.state.otp5) && pattern.test(this.state.otp4)) {
      // hardcode otp 
      localStorage.setItem("user_id","7441");
      this.props.dispatch(updateUserId("7441"))

      let data = {
        otp: givenOtp,
        // email: "shubhamgundagire@gmail.com"//this.state.email
        email: this.props.email,
      }
      dispatch(checkOtp(data))
        .then((response) => {
          if (response.status === 'success') {
            this.setState({ isSuccess: true, errorMsg: '' })
            let {lenderId,userId,sessionId,lenderSfId} = response?.data;
            localStorage.setItem('auth_token',response?.token);
            localStorage.setItem('userId',response?.data?.userId);
            localStorage.setItem('lender_id',response?.data?.lenderId);
            localStorage.setItem('session_id',response?.data?.sessionId);
            localStorage.setItem('lender_sf_id',response?.data?.lenderSfId);

            dispatch({type:'VERIFY_OTP_SUCCESS',payload:{token:response?.token,lender_id:lenderId,user_id:userId,session_id:sessionId,lender_sf_id:lenderSfId}});
            history.push("/dashboard");
            // dispatch(salesForceLogin(obj));
            // if (response.setPassword === true) {
            //   history.push("/dashboard");
            // }
            // else {
            //   history.push("/edSetPassword/" + response.id);
            // }
          } else {
            this.setState({ isSuccess: false, errorMsg: response.message })
          }
        });
    }
  }

  handleSignup = () => {
    const { history } = this.props;
    history.push("/editmobile");
  }

  forwardFocus = (value) => {
    if (value === 1) {
      this.textInput2.current.focus();
    } else if (value === 2) {
      this.textInput3.current.focus();
    } else if (value === 3) {
      this.textInput4.current.focus();
    }else if (value === 4) {
      this.textInput5.current.focus();
    } else if (value === 5) {
      this.textInput6.current.focus();
    }
  }

  backwardFocus = (value) => {
    if (value === 6) {
      this.textInput5.current.focus();
    } else if (value === 5) {
      this.textInput4.current.focus();
    }else if (value === 4) {
      this.textInput3.current.focus();
    } else if (value === 3) {
      this.textInput2.current.focus();
    } else if (value === 2) {
      this.textInput1.current.focus();
    }
  }

  handleEditMobile = (mobile_no) => {
    this.props.dispatch(setMobileNo(mobile_no));
    this.props.history.push('/login')
  }

  handleResendOtp = (e) => {
    e.preventDefault();
    let obj = {
      email: this.state.email,
    }
    this.props.dispatch(resendOtp(obj)).then(res => {
      if (res.status === 'success') {
        this.setState({ timer: '00:30'});
        this.setState({ showTimer:true,showOtpSuccess:true,otpSuccessMessage:res?.message });
        this.startTimer();
        setTimeout(()=>{
        this.setState({showOtpSuccess:false,otpSuccessMessage:''});
        },5000)
      }else {
        this.setState({ isSuccess: false, errorMsg: res.message })
      }
      // setTimeout(() => {
      //   this.setState({ resendOtpSuccess: '', showResendOtpSuccess: false });
      // }, 5000);
    })
  }

  render() {
    const { isLoading,isOtpLoaderOn} = this.props;
    const { isSuccess, errorMsg } = this.state
    return (
      <>
        <Helmet>
          <title>Verify Otp</title>
        </Helmet>
        {isLoading ? (
          <div className="loading">Loading&#8230;</div>
        ) : ''}
        <div className="flex-w flex-tr">

          <div className="size-50 bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md primary-card">
            <div className="textpart">
              <h4 className="mtext-105 cl2 txt-left pb-1 pb-lg-5"><img src="images/logo-stride.png" /></h4>
              <h1 className="titfnt text-white">
                <span className="d-block">Welcome to Lender Portal</span>
              </h1>
            </div>
          </div>

          <div className="size-210 bor10 flex-w flex-col-m p-lr-93 p-tb-30 p-lr-15-lg w-full-md login-form-img">
            <div className="lendar_centered">
               {/* <ul className="nav nav-pills nav-fill c_nav_2 justify-content-center">
                  <li className={`nav-link ico_mobile selected`}>
                    <a style={{cursor:'pointer'}} href={void(0)} >Mobile</a>
                  </li>
                  <li className={` nav-link ico_email`}>
                    <a style={{cursor:'pointer'}} href={void(0)} onClick={()=>this.switchTab(1)}>Email</a>
                  </li>
                </ul> */}
              <form >
                <div className="fields_bg_1">
                  <div className="lendar_minh1">
                    <h3>Two Factor Authentication</h3>
                    <div className="checkbox mb-4">
                      <label>An OTP has been sent to your registered email address <b style={{ color: 'blue',fontWeight:400 }}> {this.state.email?`${this.state.email.substring(0, 6)}${'*'.repeat(Math.max(this.state.email.split('@')[0].length - 6, 0))}@${this.state.email.split('@')[1]}`:""}</b>. Please enter the code below to verify your identity. <button type="button" className="ml-2"></button></label>
                    </div>
                    {this.state.showOtpSuccess && (isSuccess) && <div class="alert alert-success alert-dismissible fade show" role="alert">
                    {this.state.otpSuccessMessage}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>}
                      {isSuccess === false && errorMsg !== '' ?(<div class="alert alert-danger mb-4" role="alert">
                        {errorMsg}
                      </div>):("")}
                    <div className={`otpfields d-flex justify-content-between ${isSuccess == false && errorMsg != '' ? "err" : ""}`}>
                      <input
                        className="otp"
                        name="otp1"
                        id="otp1"
                        type="text"
                        autoComplete="off"
                        value={this.state.otp1 ? this.state.otp1 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp1", e)}
                        tabIndex="1"
                        maxLength="1"
                        // placeholder={0}
                        ref={this.textInput1}
                        onKeyUp={e => this.inputfocus(e, 'otp1')}
                        onFocus={() => this.setState({ showResendOtp: false })}
                      />
                      <input
                        className="otp"
                        name="otp2"
                        id="otp2"
                        type="text"
                        autoComplete="off"
                        value={this.state.otp2 ? this.state.otp2 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp2", e)}
                        tabIndex="2"
                        maxLength="1"
                        // placeholder={0}
                        ref={this.textInput2}
                        onKeyUp={e => this.inputfocus(e, 'otp2')}
                      />
                      <input
                        className="otp"
                        name="otp3"
                        id="otp3"
                        type="text"
                        // placeholder={0}
                        value={this.state.otp3 ? this.state.otp3 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp3", e)}
                        tabIndex="3"
                        maxLength="1"
                        ref={this.textInput3}
                        onKeyUp={e => this.inputfocus(e, 'otp3')}
                      />
                      <input
                        className="otp"
                        name="otp4"
                        id="otp4"
                        type="text"
                        // placeholder={0}
                        autoComplete="off"
                        value={this.state.otp4 ? this.state.otp4 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp4", e)}
                        tabIndex="4"
                        maxLength="1"
                        ref={this.textInput4}
                        onKeyUp={e => this.inputfocus(e, 'otp4')}
                      />
                      <input
                        className="otp"
                        name="otp5"
                        id="otp5"
                        type="text"
                        // placeholder={0}
                        autoComplete="off"
                        value={this.state.otp5 ? this.state.otp5 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp5", e)}
                        tabIndex="5"
                        maxLength="1"
                        ref={this.textInput5}
                        onKeyUp={e => this.inputfocus(e, 'otp5')}
                      />
                      <input
                        className="otp"
                        name="otp6"
                        id="otp6"
                        type="text"
                        // placeholder={0}
                        autoComplete="off"
                        value={this.state.otp6 ? this.state.otp6 : ''}
                        onKeyPress={this.keyPressed}
                        onChange={e => this.handleChange("otp6", e)}
                        tabIndex="6"
                        maxLength="1"
                        ref={this.textInput6}
                        onKeyUp={e => this.inputfocus(e, 'otp6')}
                      />
                    </div>
                  </div>
                  <div className="text-center mr-btn-sty my-4 d-flex align-items-center justify-content-center">
                      {isOtpLoaderOn?"Sending a new OTP":"Haven't received the email?"}
                     {isOtpLoaderOn?<div class="spinner-border spinner-border-sm mx-3 " role="status">
                      <span class="sr-only">Loading...</span>
                      </div>:(this.state.showTimer?<b className="m-2">Resend  in {this.state.timer}</b>:<button style={{ color: 'blue' }} className="mx-2" onClick={(e) => this.handleResendOtp(e)}> Send again</button>)}
                    </div>
                  
                  <div className="btn btn-dark btn-full"  onClick={()=>this.handleSubmit(null)}>
                    Confirm
                  </div>
                  {/* {this.state.showResendOtpSuccess && <div className="text-success mt-2">{this.state.resendOtpSuccess}</div>} */}
                </div>
              </form>
            </div>

          </div>

        </div>

        {/* <div className="lendar_page">
        <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 lendar_left d-flex align-items-center justify-content-center">
            <div className="lendar_logo">Eduvanz.</div>
            <div className="lendar_welcome">Welcome to Lender Portal</div>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
          
            </div>
          </div>
        </div>
      </div> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, log_mobile, log, user_id, token_id, isLoading,email,isUserAuthenticated,isOtpLoaderOn } = state.auth;
  const { message } = state.message;
  return {
    log_mobile,
    isLoggedIn,
    isLoading,
    token_id,
    message,
    user_id,
    log,
    email,
    isUserAuthenticated,
    isOtpLoaderOn
  };
}

export default connect(mapStateToProps)(VerifyOtp);
