import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URI;

class UserService {
  get(Url) {
    return axios
      .get(API_URL + Url,{ headers: authHeader() })
      .then((response) => {
        if(response.status === 401){
          localStorage.clear();
        }
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
        }
        return error?.response?.data;
      });
  }

  post(getdata, Url) {
    return axios
      .post(API_URL + Url, getdata,{ headers: authHeader() })
      .then((response) => {
        if(response.status === 401){
          localStorage.clear();
        }
        return response.data;
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
        }
        return error?.response?.data;
      });
  }

  getLeadContent() {
    return axios.get(API_URL + "leads",{ headers: authHeader() });
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
  
}

export default new UserService();
