import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Sidebar from "../common/sidebar";
import { Redirect } from "react-router-dom";
import { getDashboardCount, getDisbursalHistory, getRepaymentHistory, getDisHisChartData, getReHisChartData } from "../actions/leads";
import { getDashboardSummary,getLeads } from "../actions/users";
// import { Chart } from 'react-charts'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,  
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Header from "../common/header";
import axios from 'axios'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,  
  Title,
  Tooltip,
  Legend,
);

const MONTHLY = "MONTHLY";
const QUATERLY = "QUARTERLY";
const YEARLY = "YEARLY"
const TODAY = "TODAY";
const THIS_WEEK = "THIS-WEEK";
const THIS_MONTH = 'THIS-MONTH'
const SIX_MONTH = "SIX-MONTHS"

export const options = {
  responsive: true,
  scales:{
    x : {
      grid: {
        display: false
      }
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      backgroundColor: 'rgba(132, 177, 209, 1)',
    },
  },     
};

const labels = [];
const labelsQa = ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'];


const data = {
  labels,
  datasets: [
    {
      label: 'Amount',
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#1F78B4',
      borderColor: '#1F78B4',
      borderWidth: 3.5,
      data: []
    }
  ]
}
export const data2 = {
  labels,
  datasets: [
    {
      label: 'Amount',
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#1F78B4',
      borderColor: '#1F78B4',
      borderWidth: 3.5,
      data: [500000, 650000, 750000, 850000, 900000, 1000000, 1150000, 1200000, 1300000, 1400000, 1550000, 1600000]
    }
  ]
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.getDisGraph = this.getDisGraph.bind(this);
    this.getReGraph = this.getReGraph.bind(this);
    this.optionClick = this.optionClick.bind(this);
    this.state = {
      username: "",
      password: "",
      loading: false,
      selectedTab: 1,
      disbursalChartHide: false,
      repaymentChartHide: false,
      disDur: MONTHLY,
      reDur: MONTHLY,
      disGraphDataIs: data,
      disTableDataIs: null,
      reGraphDataIs: data2,
      reTableDataIs: null,
      disOpt: 1,
      reOpt: 1,
      duration:TODAY,
      dash_summary_data : {},
      isDashboardDataFetching :true,
      isDisbursalDataFetching:true,
      dashboardSummaryData:null,
      dashboardDisbursalGraphData:data,
      sumaryDuration:TODAY,
      graphDuration:MONTHLY,
      lender_sfid: '',
      dataToPull:'ALL',
      disbursalTableData:[]
    };
  }

  async componentDidMount() {
    let lender_id = localStorage.getItem('lender_sf_id');
    const { dispatch, user_sfid } = this.props
    let data = { "duration": "today", lender_sfid: user_sfid }
    let obj = {
      lender_sfid: user_sfid
    }
    // let obj1 = {
    //   lender_sfid: '0018n000006Y03hAAC',
    //   duration:TODAY,
    // }
    let dashboardReqBody = {
      lender_sfid: lender_id,
      sumary_duration:TODAY,
      graphDuration:MONTHLY
    }
    this.setState({isDashboardDataFetching:true,isDisbursalDataFetching:true});
    await this.getDashboardData(this.state.sumaryDuration,this.state.graphDuration,this.state.dataToPull)
    this.setState({isDashboardDataFetching:false,isDisbursalDataFetching:false});

    // this.props.dispatch(getLeads(obj));
    // this.props.dispatch(getDashboardSummary(obj1)).then(res => {
    //   let datafilter  = {
    //     disbursal : res.disbursedSummary && res.disbursedSummary.length > 0 ? res.disbursedSummary[0] : {},
    //     repayment : res.repaymentSummary && res.repaymentSummary.length > 0 ? res.repaymentSummary[0] : {},
    //     pendingApro : res.approvalPendingSummary && res.approvalPendingSummary.length > 0 ? res.approvalPendingSummary[0] : {},
    //     pendingDisb : res.disbursalPendingSummary && res.disbursalPendingSummary.length > 0 ? res.disbursalPendingSummary[0] : {},
    //     rejectedDecl : res.declinedSummary && res.declinedSummary.length > 0 ? res.declinedSummary[0] : {},  
    //     rejectedDrop : res.droppedSummary && res.droppedSummary.length > 0 ? res.droppedSummary[0] : {},  
    //    }
    //    this.setState({dash_summary_data:datafilter});
    //   });
    // dispatch(getDashboardCount(obj));
    // dispatch(getDisbursalHistory(data));
    // dispatch(getRepaymentHistory(data));
    // this.getDisGraph();
    // this.getReGraph();

    $('#sidebarToggleTop').on('click', function () {
      $('.sidebar-wrapper').toggleClass('toggled');
    })
    $('.sidbar-close').on('click', function () {
      $('.sidebar-wrapper').removeClass('toggled');
    })
  }

  handleTab = (value) => {
    this.setState({ selectedTab: value });
  }

  toggleDisbursalChart = () => {
    const { disbursalChartHide } = this.state;
    this.setState({ disbursalChartHide: !disbursalChartHide })
  }

  toggleRepaymentChart = () => {
    const { repaymentChartHide } = this.state;
    this.setState({ repaymentChartHide: !repaymentChartHide })
  }

  getDisGraph = () => {
    const { user_sfid } = this.props
    const dataObj = { 'duration': this.state.disDur, lender_sfid: user_sfid }
    this.props.dispatch(getDisHisChartData(dataObj)).then(response => {
      if (response.status == "success") {
        if(this.state.disDur == MONTHLY){
          this.drawDisGraphMon(response.disburseDataMonthly)
        }else if(this.state.disDur == QUATERLY){
          this.drawDisGraphQua(response.disburseDataQuarterly)
        }else if(this.state.disDur == YEARLY){
          this.drawDisGraphYear(response.disburseDataYearly)
        }
      }
    })
      .catch(error => {
      })

  }

  getReGraph = () => {
    const { user_sfid } = this.props
    const dataObj = { 'duration': this.state.reDur, lender_sfid: user_sfid }
    this.props.dispatch(getReHisChartData(dataObj)).then(response => {
      if (response.status == "success") {
        if(this.state.reDur == MONTHLY){
          this.drawReGraphMon(response.repayDataMonthly)
        }else if(this.state.reDur == QUATERLY){
          this.drawReGraphQua(response.repayDataQuarterly)
        }else if(this.state.reDur == YEARLY){
          this.drawReGraphYear(response.repayDataYearly)
        }
      }
    })
      .catch(error => {
      })

  }

  optionClick = (e) => {

  }
  handleDisbursalGraph= async(value)=>{
    this.setState({graphDuration:value,isDisbursalDataFetching:true});
    await this.getDashboardData(this.state.sumaryDuration,value,'DISBURSAL-GRAPH'); 
    this.setState({isDisbursalDataFetching:false});    
  }
  handleDashboardSummary = async (value) => {
    this.setState({sumaryDuration:value,isDashboardDataFetching:true});
    await this.getDashboardData(value,this.state.graphDuration,'DASHBOARD-SUMMARY');
    this.setState({isDashboardDataFetching:false});
  
  }
  getDashboardData = async (dashboard_time_interval,graph_time_interval,data_to_pull)=>{
    try {
  
      let params = {
       lender_id:localStorage.getItem('lender_sf_id'),
       dashboard_time_interval,
       graph_time_interval,
       data_to_pull
      }
      let headers = {
        "x-api-key":process.env.REACT_APP_AUTH_API_KEY,
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem('auth_token')
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URI}lenderui/dashboard`, { params,headers  });

      if(response?.status === 200 && response?.data?.status === 'success'){
        if(data_to_pull === 'ALL' || data_to_pull === 'DASHBOARD-SUMMARY'){
          this.setState({
            dashboardSummaryData : response?.data?.data?.dashboardStats,isDashboardDataFetching:false
          })
        }
        if(data_to_pull === 'ALL' || data_to_pull === 'DISBURSAL-GRAPH'){
          let yAxis = [],xAxis=[];
          response?.data?.data?.disbursalGraph?.graphData?.map((ele)=>{
            xAxis.push(ele?.unit);
            yAxis.push(ele?.value);            
          })
          let dataToSet = {
            labels:xAxis,
            datasets: [
              {
                label: 'Amount',
                fill: false,
                lineTension: 0.5,
                backgroundColor: '#1F78B4',
                borderColor: '#1F78B4',
                borderWidth: 3.5,
                data: yAxis
              }
            ]
          }
          let tableData = response?.data?.data?.disbursalGraph?.graphData;
          this.setState({
            dashboardDisbursalGraphData : dataToSet,disbursalTableData:tableData
          })
        }
      }      
    } catch (error) {
    }
  }
  render() {
    const { isLoading, user_id, disbursal_history, dashboard_disbursal, dashboard_repayments, dashboard_rejected, dashboard_pending, repayment_history, } = this.props;
    const { disbursalChartHide, repaymentChartHide, dash_summary_data } = this.state;
    const {disbursal, repayment, pendingApro,pendingDisb, rejectedDrop, rejectedDecl} = dash_summary_data;
     if (!localStorage.getItem("user_id") || !localStorage.getItem("auth_token") || !user_id) {
      return <Redirect to="/login" />
    }
    return (
      <>
        <Helmet>
          <title>Lender - Dashboard </title>
        </Helmet>
        {isLoading ? (
          <div className="loading">Loading&#8230;</div>
        ) : ''}
        <div id="wrapper">
          <Sidebar />
          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              {/* Topbar */}

              <Header
                title={'Dashboard'}
                isSearchEnable={true}
                dispatch={this.props.dispatch}
                type={"global"}
                history={this.props.history}
              />
              <div className="container-fluid leads_header">
                <div className="row align-items-center">
                  <div className="col-md-3"></div>
                  <div className="col-md-9 text-right">
                    <ul className="btn_lists">
                      <li>
                        <a href="#" className={this.state.sumaryDuration === TODAY ? "active" : ''} onClick={() => this.handleDashboardSummary(TODAY)}>
                          Today
                  </a>
                      </li>
                      <li>
                        <a href="#" className={this.state.sumaryDuration === THIS_WEEK ? "active" : ''} onClick={() => this.handleDashboardSummary(THIS_WEEK)}>
                          This week
                  </a>
                      </li>
                      <li>
                        <a href="#" className={this.state.sumaryDuration === THIS_MONTH ? "active" : ''} onClick={() => this.handleDashboardSummary(THIS_MONTH)}>
                          This month
                  </a>
                      </li>
                      <li>
                        <a href="#" className={this.state.sumaryDuration === SIX_MONTH ? "active" : ''} onClick={() => this.handleDashboardSummary(SIX_MONTH)}>
                          6 months
                  </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="container-fluid leads_header summary-spinner-box">
              {this.state.isDashboardDataFetching && !(this.state.isLoading) && <div className="summaryloading">Loading&#8230;</div>} 
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <ul className="leads_short_info">
                      <li className="disbursal">                      
                        <div className="inner">
                          <span className="title">Disbursal</span>
                          <div className="centeredin">
                            <span className="amt">
                              <span className="ruppe">₹</span> {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.disbursal?.amount ? this.state.dashboardSummaryData?.disbursal?.amount?.toLocaleString("en-US") : '0'} <span className="innerinr" style={{ color: '#000' }}>Amount</span>
                            </span>
                            <span className="cases">
                              {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.disbursal?.cases ? this.state.dashboardSummaryData?.disbursal?.cases : '0'} <span className="innerinr">Cases</span>
                            </span>
                          </div>
                          <span className="atsize">Average Ticket Size <span className="inneratsize">{this.state.dashboardSummaryData && this.state.dashboardSummaryData?.disbursal?.avrageTicketSize ?`₹ ${(this.state.dashboardSummaryData?.disbursal?.avrageTicketSize).toLocaleString("en-US")}` : '₹ 0'}</span></span>
                        </div>
                      </li>
                      <li className="pending">
                        <div className="inner">
                          <span className="title">Pending</span>
                          {/* <div className="centeredin bx_approved"> */}
                          <div className="centeredin bx_rejected" style={{ backgroundColor: '#EDEDED' }}>
                            <span className="amt">
                              {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.pendding?.approval ? this.state.dashboardSummaryData?.pendding?.approval : '0'}<span className="innerinr">Approval</span>
                            </span>
                            <span className="cases" style={{ backgroundColor: '#EDEDED' }}>
                              {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.pendding?.disbursal ? this.state.dashboardSummaryData?.pendding?.disbursal : '0'} <span className="innerinr" >Disbursal</span>
                            </span>
                          </div>
                          <span className="atsize">Pending Amount<span className="inneratsize">₹ {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.pendding?.amount ? this.state.dashboardSummaryData?.pendding?.amount.toLocaleString("en-US") : '0'}</span></span>
                        </div>
                      </li>
                      <li className="rejected">
                        <div className="inner">
                          <span className="title">Rejected</span>
                          {/* <div className="centeredin bx_rejected"> */}
                          <div className="centeredin bx_approved">
                            <span className="amt">
                              {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.rejected?.declined ? this.state.dashboardSummaryData?.rejected?.declined : '0'}<span className="innerinr">Declined</span>
                            </span>
                            <span className="cases">
                              {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.rejected?.dropped ? this.state.dashboardSummaryData?.rejected?.dropped : '0'} <span className="innerinr">Dropped</span>
                            </span>
                          </div>
                          <span className="atsize">Rejected Amount <span className="inneratsize">₹ {this.state.dashboardSummaryData && this.state.dashboardSummaryData?.rejected?.amount ? this.state.dashboardSummaryData?.rejected?.amount?.toLocaleString("en-US")  : '0'}</span></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End of Topbar */}
              {/* Begin Page Content */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 text-right">
                    <ul className="btn_lists">
                      <li>
                        <a href="#" className={`disOpt ${this.state.graphDuration == MONTHLY ? 'active' : ''}`} onClick={(e) => {
                            this.handleDisbursalGraph(MONTHLY)
                            }}>
                          Monthly
                        </a>
                      </li>
                      <li>
                        <a href="#" className={`disOpt ${this.state.graphDuration == QUATERLY ? 'active' : ''}`} onClick={(e) => {
                          this.handleDisbursalGraph(QUATERLY)
                        }}>
                          Quarterly
                        </a>
                      </li>
                      <li>
                        <a href="#" className={`disOpt ${this.state.graphDuration == YEARLY ? 'active' : ''}`} onClick={(e) => {
                          this.handleDisbursalGraph(YEARLY)
                        }}>
                          Yearly
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row disbursal-graph">
                {this.state.isDisbursalDataFetching && !(this.state.isLoading) && <div className="summaryloading">Loading&#8230;</div>} 
                  <div className="col-md-12 pt-2">
                    <div className="boxed">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                          <h5>Disbursal History</h5>
                          <div className="text-right">
                            <button className="s-h" onClick={this.toggleDisbursalChart}>{(!disbursalChartHide) ? "Hide Table" : "View Table"}</button>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className={(!disbursalChartHide) ? "col-lg-7" : "col-lg-12"}>
                          <Line options={options} data={this.state.dashboardDisbursalGraphData} className="line-chart"/>
                        </div>
                        {(!disbursalChartHide) ?
                          <div className="col-lg-5 table-body">
                            <table className="w-100 month-table">
                              <thead>
                                <tr>
                                  <th>{this.state.graphDuration === 'MONTHLY'?'Month':this.state.graphDuration === 'QUARTERLY'?'Quarter':this.state.graphDuration === 'YEARLY'?'Year':""}</th>
                                  <th>Disbursals (#)</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.disbursalTableData && this.state.disbursalTableData.map((item, index) => (
                                  <tr key={`disbursel-${index}`}>
                                    <td>{item.unit ? item.unit : '-'}</td>
                                    <td>{item.count ? item.count : '-'}</td>
                                    <td>₹ {item.value ? item.value.toLocaleString("en-US") : '-'}</td>
                                  </tr>
                                ))}
                              </tbody>

                            </table>
                          </div>
                          : ''
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
            </div>
            {/* End of Main Content */}
          </div>
          {/* End of Content Wrapper */}
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoading, isLoggedIn, user_id, user_sfid } = state.auth;
  const { disbursal_history, dashboard_disbursal, dashboard_repayments, dashboard_pending, dashboard_rejected, repayment_history } = state.leads;
  const { message } = state.message;
  const {userProfile} = state.user;
  return {
    dashboard_disbursal,
    dashboard_repayments,
    dashboard_rejected,
    dashboard_pending,
    disbursal_history,
    repayment_history,
    isLoggedIn,
    isLoading,
    user_sfid,
    message,
    user_id,
    userProfile
  };
}

export default connect(mapStateToProps)(Dashboard);
